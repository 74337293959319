var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { MainComponentHeader } from "../../shared/components/MainComponentHeader";
import { PageLayoutComponent } from "../../shared/components/layout/PageLayoutComponent";
import { Box, CircularProgress, Collapse, IconButton, Paper, Stack, Typography, styled, } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { backgroundColor, footerHeight, headerHeight, primaryColor, } from "../../shared/style/Style-MainTheme";
import { FilterContainerComponent } from "../components/FilterContainerComponent";
import { JSONViewer } from "../components/JSONViewerComponent";
import { useLinkedDataPageComponent } from "./LinkedDataUseCases";
var Item = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({ backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff" }, theme.typography.body2), { padding: theme.spacing(1), textAlign: "center", color: theme.palette.text.secondary }));
});
export function LinkedDataComponent() {
    var _a = useLinkedDataPageComponent(null), model = _a.model, operations = _a.operations;
    var t = useTranslation(["ExplanationPage"]).t;
    var _b = __read(React.useState(true), 2), showFilters = _b[0], setShowFilters = _b[1];
    var _c = __read(React.useState(false), 2), dataFetched = _c[0], setDataFetched = _c[1];
    var _d = __read(React.useState(false), 2), notFound = _d[0], setNotFound = _d[1];
    var _e = __read(React.useState("LD"), 2), view = _e[0], setView = _e[1];
    var handleFilterBtnClick = function () {
        setShowFilters(function (prev) { return !prev; });
    };
    var handleSearchBtnClick = function (filter) {
        operations.fetchData(filter, view);
        setDataFetched(true);
    };
    var headerRef = React.createRef();
    var _f = __read(React.useState(230), 2), heightFromTop = _f[0], setHeightFromTop = _f[1];
    var getHeightOffset = function () {
        var _a, _b;
        if ((_a = headerRef === null || headerRef === void 0 ? void 0 : headerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) {
            return (((_b = headerRef === null || headerRef === void 0 ? void 0 : headerRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight) +
                Number(footerHeight) +
                Number(headerHeight) +
                18);
        }
        return 230;
    };
    useEffect(function () {
        setHeightFromTop(getHeightOffset());
    }, []);
    useEffect(function () {
        setHeightFromTop(getHeightOffset());
        if (dataFetched == true) {
            if (model.data.length == 0) {
                setNotFound(true);
            }
            else {
                setNotFound(false);
            }
        }
    }, [model.selectionConfig]);
    var fetch = function (viewName) {
        setView(viewName);
        operations.fetchData(model.selectedFilters, viewName);
        setDataFetched(true);
    };
    return (React.createElement(PageLayoutComponent, null,
        React.createElement(Stack, { direction: "row", spacing: 2, sx: {
                "&& .MuiPaper-root": {},
            } },
            React.createElement(Collapse, { orientation: "horizontal", in: showFilters, collapsedSize: 50, sx: {
                    maxWidth: "30%",
                    borderRight: "1px solid gray",
                    "&& .MuiCollapse-wrapperInner": {
                        width: "100%",
                    },
                } },
                showFilters && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: {
                            zIndex: "101",
                            position: "relative",
                        } },
                        React.createElement(Box, { sx: {
                                display: "flex",
                                flexDirection: "row",
                                padding: 1,
                                justifyContent: "center",
                                gap: 2,
                            } },
                            React.createElement(Typography, { variant: "h3" }, t("Filter_Settings"))),
                        React.createElement(FilterContainerComponent, { selectionConfig: model.selectionConfig, searchButtonText: model.searchButtonText, loading: model.loading, handleSearchBtnClick: function (data) {
                                handleSearchBtnClick(data);
                            } })),
                    React.createElement("div", { style: {
                            float: "inline-end",
                        } },
                        React.createElement(IconButton, { color: "primary", "aria-label": "apply filter", onClick: handleFilterBtnClick, sx: {
                                zIndex: "100",
                                position: "absolute",
                                top: "45%",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "22px",
                                cursor: "pointer",
                                color: "".concat(backgroundColor),
                                ":hover": {
                                    backgroundColor: "".concat(primaryColor),
                                },
                                backgroundColor: "".concat(primaryColor),
                                background: "linear-gradient( 90deg , ".concat(backgroundColor, " 50%, ").concat(primaryColor, " 50%)"),
                                padding: "0",
                                height: "48px",
                                width: "48px",
                                marginLeft: "-24px",
                                "&& svg": {
                                    marginLeft: "10px",
                                    marginRight: "-10px",
                                    fontSize: "xxx-large",
                                },
                            } },
                            React.createElement(KeyboardArrowLeftRoundedIcon, null))))),
                !showFilters && (React.createElement("div", { style: {
                        backgroundColor: "".concat(primaryColor),
                        height: "100%",
                    } },
                    React.createElement("div", { style: {
                            display: "inline-block",
                            transform: "rotate(-90deg) translateX(-110%)",
                            transformOrigin: "top left",
                            width: "max-content",
                            zIndex: "100",
                            position: "absolute",
                            left: "10px",
                        } },
                        React.createElement(Typography, { variant: "h3", sx: {
                                color: "".concat(backgroundColor),
                            } }, t("Filter_Settings"))),
                    React.createElement(IconButton, { color: "primary", "aria-label": "apply filter", onClick: handleFilterBtnClick, sx: {
                            top: "45%",
                            left: "25px",
                            position: "absolute",
                            height: "48px",
                            width: "48px",
                            backgroundColor: "".concat(primaryColor),
                            color: "rgb(255, 255, 255)",
                            ":hover": {
                                backgroundColor: "".concat(primaryColor),
                            },
                            "&& svg": {
                                marginRight: "-15px",
                                fontSize: "xxx-large",
                            },
                        } },
                        React.createElement(KeyboardArrowRightRoundedIcon, null))))),
            React.createElement(Item, { style: {
                    width: "100%",
                    boxShadow: "15px 0 15px -15px inset",
                } },
                React.createElement(Box, { ref: headerRef },
                    React.createElement(Box, null,
                        React.createElement(MainComponentHeader, { heading: model.heading.main }))),
                React.createElement("div", { style: {
                        height: "calc(100vh - ".concat(heightFromTop, "px)"),
                        maxHeight: "calc(100vh - ".concat(heightFromTop, "px)"),
                        overflowY: "auto",
                    } }, model.loading ? (React.createElement(Box, { sx: {
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        left: "0",
                        top: "50%",
                        zIndex: "9999",
                    } },
                    React.createElement(CircularProgress, null))) : (React.createElement(JSONViewer, { data: model.data, fetch: fetch, view: view, notfound: notFound ? "true" : "false" })))))));
}
