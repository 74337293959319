var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
var Alert = React.forwardRef(function (props, ref) {
    return React.createElement(MuiAlert, __assign({ elevation: 6, ref: ref, variant: "filled" }, props));
});
export var ErrorSnackbar = function (_a) {
    var open = _a.open, handleClose = _a.handleClose;
    var t = useTranslation(["ErrorPage"]).t;
    return (React.createElement(Snackbar, { open: open, onClose: handleClose, anchorOrigin: { vertical: "top", horizontal: "center" }, sx: { mt: 2, width: "auto" } },
        React.createElement(Alert, { onClose: handleClose, severity: "error", sx: {
                width: "300%",
                backgroundColor: "red",
                fontSize: "1rem",
                padding: "6px 24px",
                minWidth: "300px",
                maxWidth: "600px",
            } }, t("UNKNOWN-ERROR"))));
};
