var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { WELCOME } from "./../../shared/infrastructure/router/routes";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
function getTranslations(t) {
    var hardCodedSubtitle = t("Subtitle");
    var hardCodedContent = t("MainText");
    return { hardCodedSubtitle: hardCodedSubtitle, hardCodedContent: hardCodedContent };
}
var useWelcomePageComponent = function () {
    var t = useTranslation(["HomePage"]).t;
    var _a = getTranslations(t), hardCodedSubtitle = _a.hardCodedSubtitle, hardCodedContent = _a.hardCodedContent;
    var _b = __read(useState(hardCodedSubtitle), 2), subtitle = _b[0], setSubtitle = _b[1];
    var _c = __read(useState(hardCodedContent), 2), content = _c[0], setContent = _c[1];
    useEffect(function () {
        callApiService("".concat(WELCOME, "?locale=").concat(i18next.language))
            .then(function (response) {
            if (response.data) {
                var data = response.data.data;
                setSubtitle(data.attributes.subheading);
                setContent(data.attributes.content);
            }
            return response;
        })
            .catch(function (error) {
            throw error;
        });
    }, [i18next.language]);
    var title = "ZooNotify";
    return {
        model: {
            title: title,
            subtitle: subtitle,
            content: content,
        },
        operations: {},
    };
};
export { useWelcomePageComponent };
