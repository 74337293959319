import React from "react";
import { MainComponentHeader } from "../../shared/components/MainComponentHeader";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { EvaluationDivisionContainer } from "./EvaluationDivisionContainer";
import { useEvaluationContentComponent } from "./evaluationsContentUseCases";
export function EvaluationMainContent(_a) {
    var _b = useEvaluationContentComponent(), model = _b.model, operations = _b.operations;
    var t = useTranslation(["ExplanationPage"]).t;
    return (React.createElement(React.Fragment, null,
        React.createElement(MainComponentHeader, { heading: model.heading }),
        React.createElement(Box, { sx: {
                maxHeight: "calc(100vh - 130px)",
                overflowY: "auto",
            } }, !model.loading &&
            Object.keys(model.evaluationsData)
                .filter(function (value) { return operations.showDivision(value); })
                .map(function (division) { return (React.createElement(EvaluationDivisionContainer, { key: division, title: t(division), divisionData: model.evaluationsData[division], downloadGraphButtonText: model.downloadGraphButtonText, downloadDataButtonText: model.downloadDataButtonText })); }))));
}
