var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import { BodyRouterComponent } from "../infrastructure/router/Body-Router.component";
var wrapperStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 100%;\n    display: flex;\n    z-index: 0;\n    flex-direction: column;\n    box-sizing: border-box;\n"], ["\n    height: 100%;\n    display: flex;\n    z-index: 0;\n    flex-direction: column;\n    box-sizing: border-box;\n"])));
var bodyStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex: 1 1 0;\n    z-index: 0;\n    box-sizing: border-box;\n    overflow-x: hidden;\n    overflow-y: hidden;\n"], ["\n    flex: 1 1 0;\n    z-index: 0;\n    box-sizing: border-box;\n    overflow-x: hidden;\n    overflow-y: hidden;\n"])));
export function MainLayoutComponent() {
    return (_jsx("div", __assign({ css: wrapperStyle }, { children: _jsx(BrowserRouter, { children: _jsx("div", __assign({ css: bodyStyle }, { children: _jsx(BodyRouterComponent, {}) })) }) })));
}
var templateObject_1, templateObject_2;
