import { useTranslation } from "react-i18next";
var useEvaluationPageComponent = function () {
    var t = useTranslation(["ExplanationPage"]).t;
    return {
        model: {
            mainHeading: t("Heading"),
            sideBarTitle: t("Filter_Settings"),
        },
    };
};
export { useEvaluationPageComponent };
