import { Link, List, ListItem, Tooltip, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { API_DOCUMENTATION_URL, pageRoute, } from "../../infrastructure/router/routes";
export function FooterLinkListComponent(props) {
    var _a = useTranslation(["Footer"]), t = _a.t, i18n = _a.i18n;
    var theme = useTheme();
    var linkStyle = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        textDecoration: "none",
        color: "inherit",
        "&:focus": {
            outline: "none",
        },
    };
    var disableLinkStyle = {
        width: "100%",
        margin: 0,
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        color: "gray",
    };
    var footerElementStyle = {
        width: "fit-content",
        padding: "0.5em",
        flex: "1 1 auto",
        listStyleType: "none",
        cursor: "pointer",
        transition: "0.3s",
        color: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
        boxSizing: "inherit",
    };
    var footerContentStyle = {
        margin: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        flexGrow: 1,
        textDecoration: "none",
        boxSizing: "inherit",
        padding: "0",
    };
    var submitProblemLink = (React.createElement(Link, { href: "mailto:".concat(props.supportMail, "?subject=ZooNotify-Problem:&body=").concat(t("Content.MailText")), sx: linkStyle },
        React.createElement(Typography, null, t("Content.Mail"))));
    if (props.supportMail === undefined) {
        var supportMailErrorText = t("Content.SupportError");
        submitProblemLink = (React.createElement(Tooltip, { sx: {
                backgroundColor: "transparent",
                color: theme.palette.error.main,
                fontSize: "9px",
                paddingRight: 0,
            }, title: supportMailErrorText, placement: "top" },
            React.createElement(Box, { sx: disableLinkStyle },
                React.createElement(Typography, null, t("Content.Mail")))));
    }
    var apiDocumentationUrl = API_DOCUMENTATION_URL;
    var bfrLink = i18n.language === "en"
        ? "https://www.bfr.bund.de/en/home.html"
        : "https://www.bfr.bund.de/de/start.html";
    return (React.createElement(List, { sx: footerContentStyle },
        React.createElement(ListItem, { sx: footerElementStyle },
            React.createElement(Link, { href: bfrLink, target: "_blank", rel: "noreferrer", sx: linkStyle },
                React.createElement(Typography, null, t("Content.Bfr")))),
        React.createElement(ListItem, { sx: footerElementStyle },
            React.createElement(Link, { href: "https://foodrisklabs.bfr.bund.de/foodrisk-labs/", target: "_blank", rel: "noreferrer", sx: linkStyle },
                React.createElement(Typography, null, "FoodRisk-Labs"))),
        React.createElement(ListItem, { sx: footerElementStyle },
            React.createElement(Link, { href: apiDocumentationUrl, target: "_blank", rel: "noopener noreferrer", sx: linkStyle },
                React.createElement(Typography, null, t("Content.Api")))),
        React.createElement(ListItem, { sx: footerElementStyle },
            React.createElement(NavLink, { to: pageRoute.dpdPagePath, style: linkStyle },
                React.createElement(Typography, null, t("Content.DataProtection")))),
        React.createElement(ListItem, { sx: footerElementStyle }, submitProblemLink)));
}
