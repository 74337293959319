var microorganism = [
    "E_COLI",
    "CAMPYLOBACTER_SPP",
    "ESBL_AMPC_E_COLI",
    "LISTERIS_MONOCYTOGENES",
    "MRSA",
    "SALMONELLA_SPP",
    "STEC",
    "CARBA_E_COLI",
    "ENTEROCOCCUS_SPP",
];
var category = ["HUHN", "PUTE", "SCHWEIN", "RIND", "DIVERSE"];
var diagramType = [
    "MDR",
    "ERREGERCHARAK",
    "SUBSTANZ_GRAPH",
    "TREND_DIAGRAMM",
];
var productionType = [
    "LEGEHENNEN",
    "MASTHAEHNCHEN",
    "MASTKALB_JUNGRIND",
    "MASTRIND",
    "MASTPUTEN",
    "MASTSCHWEIN",
    "RIND",
    "ZUCHTHUEHNER_LEGE_UND_MASTLINIE",
    "MILCHRIND",
    "DIVERSE",
];
var matrix = [
    "BLINDDARMINHALT",
    "FRISCHES_FLEISCH",
    "HACKFLEISCH",
    "KOT_STAUB",
    "SCHLACHTKOERPER",
    "HALS_HAUT",
    "MILCH",
    "MULTIPLE",
];
var division = ["FUTTERMITTEL", "TIERE", "LEBENSMITTEL", "MULTIPLE"];
var initialFilterSelection = {
    division: division,
    microorganism: microorganism,
    category: category,
    productionType: productionType,
    matrix: matrix,
    diagramType: diagramType,
};
export { category, diagramType, division, initialFilterSelection, matrix, microorganism, productionType, };
