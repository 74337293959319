import React from "react";
import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, } from "@mui/material";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { useStyles } from "./../utils/utils";
export function FilterMultiSelectionComponent(_a) {
    var selectedItems = _a.selectedItems, selectionOptions = _a.selectionOptions, label = _a.label, name = _a.name, actions = _a.actions, extra = _a.extra;
    var t = useTranslation(["ExplanationPage"]).t;
    var classes = useStyles();
    var italicWords = [
        "Salmonella",
        "coli",
        "E.",
        "Bacillus",
        "cereus",
        "monocytogenes",
        "Clostridioides",
        "difficile",
        "Yersinia",
        "Listeria",
        "enterocolitica",
        "Vibrio",
        "Baylisascaris",
        "procyonis",
        "Echinococcus",
        "Campylobacter",
    ];
    var formatMicroorganismName = function (microName) {
        if (!microName) {
            console.warn("Received null or undefined microorganism name");
            return React.createElement(React.Fragment, null);
        }
        var words = microName
            .split(/(\s+|-)/)
            .filter(function (part) { return part.trim().length > 0; });
        return words
            .map(function (word, index) {
            var italic = italicWords.some(function (italicWord) {
                return word.toLowerCase().includes(italicWord.toLowerCase());
            });
            return italic ? (React.createElement("i", { key: index }, word)) : (React.createElement("span", { key: index }, word));
        })
            .reduce(function (prev, curr) { return (React.createElement(React.Fragment, null,
            prev,
            prev ? " " : "",
            curr)); }, React.createElement(React.Fragment, null));
    };
    var isAllSelected = selectionOptions.length > 0 &&
        selectedItems.length === selectionOptions.length;
    var handleChange = function (event) {
        var value = event.target.value;
        var index = value.indexOf("all");
        var selectedItemIndex = selectedItems.indexOf("all");
        if (index !== -1 && selectedItemIndex === -1) {
            if (selectedItems.length === 0) {
                event.target.value = selectionOptions.map(function (a) { return a.value; });
            }
            else {
                event.target.value = [];
            }
        }
        actions.handleChange(event);
    };
    return (React.createElement(FormControl, { className: classes.formControl, sx: { flex: "1 1 0", width: "100%" } },
        React.createElement(InputLabel, { id: "select-label" }, label),
        React.createElement(Select, { labelId: "select-label", id: "select", name: name, multiple: true, value: selectedItems, label: label, onChange: handleChange, renderValue: function (selected) { return selected.map(function (s) { return t(s); }).join(", "); }, MenuProps: {
                PaperProps: {
                    style: {
                        maxHeight: 400,
                        width: 250,
                        overflowX: "hidden",
                    },
                },
            } },
            React.createElement(MenuItem, { value: "all" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Checkbox, { checked: isAllSelected, indeterminate: selectedItems.length > 0 && !isAllSelected })),
                React.createElement(ListItemText, { primary: t("SELECT_ALL") })),
            selectionOptions.map(function (item, index) { return (React.createElement(MenuItem, { key: index, value: item.value },
                React.createElement(Checkbox, { checked: selectedItems.includes(item.value) }),
                React.createElement(ListItemText, { primary: formatMicroorganismName(item.displayName), sx: { whiteSpace: "normal" } }))); })),
        extra));
}
