import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { ZNAccordion } from "../../shared/components/accordion/ZNAccordion";
import { usePrevalenceFilters, } from "./PrevalenceDataContext";
var italicWords = [
    "Salmonella",
    "coli",
    "E.",
    "Bacillus",
    "cereus",
    "monocytogenes",
    "Clostridioides",
    "difficile",
    "Yersinia",
    "Listeria",
    "enterocolitica",
    "Vibrio",
    "Baylisascaris",
    "procyonis",
    "Echinococcus",
    "Campylobacter",
];
var formatMicroorganismName = function (microName) {
    if (!microName) {
        console.warn("Received null or undefined microorganism name");
        return React.createElement(React.Fragment, null);
    }
    var words = microName
        .split(/([-\s])/)
        .filter(function (part) { return part.length > 0; });
    return (React.createElement(React.Fragment, null, words.map(function (word, index) {
        if (word.trim() === "" || word === "-") {
            return word;
        }
        var italic = italicWords.some(function (italicWord) {
            return word.toLowerCase().includes(italicWord.toLowerCase());
        });
        return italic ? (React.createElement("i", { key: index }, word)) : (React.createElement("span", { key: index }, word));
    })));
};
var SearchParameterEntry = function (_a) {
    var title = _a.title, value = _a.value;
    if (!value)
        return null;
    var theme = useTheme();
    return (React.createElement(Box, { sx: {
            p: 1,
            border: 1,
            borderRadius: 1,
            mb: 1,
            backgroundColor: theme.palette.grey[100],
            width: "100%",
            maxWidth: "1200px",
        } },
        React.createElement(Typography, { variant: "subtitle1", sx: {
                fontWeight: "bold",
                mr: 1,
                color: theme.palette.text.primary,
                display: "inline",
            } },
            title,
            ":"),
        React.createElement(Typography, { variant: "body2", sx: {
                color: theme.palette.text.primary,
                display: "inline",
            } }, value)));
};
var SearchParameterDisplay = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var searchParameters = _a.searchParameters;
    var theme = useTheme();
    var t = useTranslation(["PrevalencePage"]).t;
    return (React.createElement(Box, { sx: {
            pt: theme.spacing(3),
            display: "flex",
            flexDirection: "column",
            width: "100%",
        } },
        ((_b = searchParameters.microorganism) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement(SearchParameterEntry, { title: t("MICROORGANISMS"), value: React.createElement(React.Fragment, null, searchParameters.microorganism.map(function (v, index) { return (React.createElement("span", { key: index },
                formatMicroorganismName(t(v)),
                index <
                    searchParameters.microorganism.length - 1
                    ? ", "
                    : "")); })) })),
        ((_c = searchParameters.matrix) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement(SearchParameterEntry, { title: t("MATRIX"), value: searchParameters.matrix.map(function (v) { return t(v); }).join(", ") })),
        ((_d = searchParameters.sampleOrigin) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement(SearchParameterEntry, { title: t("SAMPLE_ORIGIN"), value: searchParameters.sampleOrigin
                .map(function (v) { return t(v); })
                .join(", ") })),
        ((_e = searchParameters.matrixGroup) === null || _e === void 0 ? void 0 : _e.length) > 0 && (React.createElement(SearchParameterEntry, { title: t("MATRIX_GROUP"), value: searchParameters.matrixGroup
                .map(function (v) { return t(v); })
                .join(", ") })),
        ((_f = searchParameters.samplingYear) === null || _f === void 0 ? void 0 : _f.length) > 0 && (React.createElement(SearchParameterEntry, { title: t("SAMPLING_YEAR"), value: searchParameters.samplingYear
                .map(function (v) { return t(v); })
                .join(", ") })),
        ((_g = searchParameters.superCategorySampleOrigin) === null || _g === void 0 ? void 0 : _g.length) > 0 && (React.createElement(SearchParameterEntry, { title: t("SUPER-CATEGORY-SAMPLE-ORIGIN"), value: searchParameters.superCategorySampleOrigin
                .map(function (v) { return t(v); })
                .join(", ") })),
        ((_h = searchParameters.samplingStage) === null || _h === void 0 ? void 0 : _h.length) > 0 && (React.createElement(SearchParameterEntry, { title: t("SAMPLING_STAGE"), value: searchParameters.samplingStage
                .map(function (v) { return t(v); })
                .join(", ") }))));
};
var DataGridControls = function (_a) {
    var heading = _a.heading;
    var searchParameters = usePrevalenceFilters().searchParameters;
    return (React.createElement(ZNAccordion, { title: heading, content: React.createElement(SearchParameterDisplay, { searchParameters: searchParameters }), defaultExpanded: true, centerContent: true }));
};
export { DataGridControls };
