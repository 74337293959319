import React from "react";
import { useTranslation } from "react-i18next";
import { LogoCardComponent } from "../../shared/components/logo_card/LogoCard.component";
export function ErrorPageComponent(props) {
    var t = useTranslation(["ErrorPage"]).t;
    var errorString = props.errorStatus.toString();
    var errorText = "GeneralErrorText";
    var errorTitle = "GeneralErrorTitle";
    if (props.errorStatus === 404) {
        errorText = "PageNotFoundText";
        errorTitle = "PageNotFoundTitle";
    }
    return (React.createElement(LogoCardComponent, { title: errorString, subtitle: t(errorTitle), text: t(errorText) }));
}
