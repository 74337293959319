var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useState, useEffect } from "react";
import { usePrevalenceFilters } from "./PrevalenceDataContext";
import { Box, CircularProgress, Typography, Grid, Pagination, } from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import { useTranslation } from "react-i18next";
import { MicroorganismSelect } from "./MicroorganismSelect";
import { ChartCard } from "./ChartCard";
import { getCurrentTimestamp } from "./utils";
ChartJS.register.apply(ChartJS, __spreadArray([], __read(registerables), false));
var PrevalenceChart = function () {
    var _a = usePrevalenceFilters(), prevalenceData = _a.prevalenceData, loading = _a.loading, prevalenceUpdateDate = _a.prevalenceUpdateDate;
    var chartRefs = useRef({});
    var t = useTranslation(["PrevalencePage"]).t;
    var _b = __read(useState(""), 2), currentMicroorganism = _b[0], setCurrentMicroorganism = _b[1];
    var _c = __read(useState([]), 2), availableMicroorganisms = _c[0], setAvailableMicroorganisms = _c[1];
    var _d = __read(useState(1), 2), currentPage = _d[0], setCurrentPage = _d[1];
    var chartsPerPage = 2;
    var updateAvailableMicroorganisms = function () {
        var microorganismsWithData = Array.from(new Set(prevalenceData.map(function (entry) { return entry.microorganism; })));
        setAvailableMicroorganisms(microorganismsWithData);
        if (microorganismsWithData.length > 0 && !currentMicroorganism) {
            setCurrentMicroorganism(microorganismsWithData[0]);
        }
    };
    useEffect(function () {
        if (prevalenceData.length > 0) {
            updateAvailableMicroorganisms();
        }
    }, [prevalenceData]);
    useEffect(function () {
        if (currentMicroorganism &&
            !availableMicroorganisms.includes(currentMicroorganism)) {
            setCurrentMicroorganism(availableMicroorganisms[0]);
        }
    }, [availableMicroorganisms]);
    useEffect(function () {
        setCurrentPage(1);
    }, [currentMicroorganism]);
    var yearOptions = Array.from({ length: 15 }, function (_, i) { return 2009 + i; }).reverse();
    var generateChartData = function () {
        var chartData = {};
        prevalenceData.forEach(function (entry) {
            if (entry.microorganism === currentMicroorganism) {
                var key = "".concat(entry.sampleOrigin, "-").concat(entry.matrix, "-").concat(entry.samplingStage);
                if (!chartData[key]) {
                    chartData[key] = {};
                }
                chartData[key][entry.samplingYear] = {
                    x: entry.percentageOfPositive,
                    y: entry.samplingYear,
                    ciMin: entry.ciMin,
                    ciMax: entry.ciMax,
                    numberOfSamples: entry.numberOfSamples,
                    numberOfPositive: entry.numberOfPositive,
                };
            }
        });
        return chartData;
    };
    var chartData = generateChartData();
    var chartKeys = Object.keys(chartData);
    var totalCharts = chartKeys.length;
    var totalPages = Math.ceil(totalCharts / chartsPerPage);
    var displayedChartsSet = new Set(chartKeys.slice((currentPage - 1) * chartsPerPage, currentPage * chartsPerPage));
    var allCiMaxValues = Object.values(chartData).flatMap(function (yearData) {
        return Object.values(yearData).map(function (data) { return data.ciMax; });
    });
    var maxCiPlus = Math.max.apply(Math, __spreadArray([], __read(allCiMaxValues), false));
    var xAxisMax = maxCiPlus > 25 ? 100 : 25;
    var sanitizeKey = function (key) {
        return key.replace(/[^a-z0-9_\-]/gi, "_");
    };
    var downloadChart = function (chartRef, chartKey) { return __awaiter(void 0, void 0, void 0, function () {
        var originalChart, canvasWidth, canvasHeight, tempCanvas, tempCtx, originalConfig, clonedConfig, tempChart, link, sanitizedChartKey;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        return __generator(this, function (_y) {
            switch (_y.label) {
                case 0:
                    if (!chartRef.current) {
                        console.error("Chart reference is undefined");
                        return [2];
                    }
                    originalChart = chartRef.current;
                    canvasWidth = 1380;
                    canvasHeight = 1000;
                    tempCanvas = document.createElement("canvas");
                    tempCanvas.width = canvasWidth;
                    tempCanvas.height = canvasHeight;
                    tempCtx = tempCanvas.getContext("2d");
                    if (!tempCtx) {
                        console.error("Failed to get temp canvas context");
                        return [2];
                    }
                    tempCtx.fillStyle = "white";
                    tempCtx.fillRect(0, 0, canvasWidth, canvasHeight);
                    originalConfig = originalChart.config;
                    clonedConfig = {
                        type: "bar",
                        data: __assign({}, originalConfig.data),
                        options: __assign(__assign({}, originalConfig.options), { responsive: false, devicePixelRatio: 1, animation: false, layout: {
                                padding: {
                                    top: 60,
                                    bottom: 40,
                                    left: 60,
                                    right: 80,
                                },
                            }, scales: {
                                x: __assign(__assign({}, (_b = (_a = originalConfig.options) === null || _a === void 0 ? void 0 : _a.scales) === null || _b === void 0 ? void 0 : _b.x), { ticks: __assign(__assign({}, (_e = (_d = (_c = originalConfig.options) === null || _c === void 0 ? void 0 : _c.scales) === null || _d === void 0 ? void 0 : _d.x) === null || _e === void 0 ? void 0 : _e.ticks), { font: { size: 20 }, color: "black" }), title: __assign(__assign({}, (_h = (_g = (_f = originalConfig.options) === null || _f === void 0 ? void 0 : _f.scales) === null || _g === void 0 ? void 0 : _g.x) === null || _h === void 0 ? void 0 : _h.title), { display: true, text: "Prevalence (%)", color: "black", font: { size: 22, weight: "bold" } }) }),
                                y: __assign(__assign({}, (_k = (_j = originalConfig.options) === null || _j === void 0 ? void 0 : _j.scales) === null || _k === void 0 ? void 0 : _k.y), { ticks: __assign(__assign({}, (_o = (_m = (_l = originalConfig.options) === null || _l === void 0 ? void 0 : _l.scales) === null || _m === void 0 ? void 0 : _m.y) === null || _o === void 0 ? void 0 : _o.ticks), { font: { size: 22 }, color: "black" }), title: __assign(__assign({}, (_r = (_q = (_p = originalConfig.options) === null || _p === void 0 ? void 0 : _p.scales) === null || _q === void 0 ? void 0 : _q.y) === null || _r === void 0 ? void 0 : _r.title), { display: true, text: "Year", color: "black", font: { size: 24, weight: "bold" } }) }),
                            }, plugins: __assign(__assign({}, (_s = originalConfig.options) === null || _s === void 0 ? void 0 : _s.plugins), { legend: __assign(__assign({}, (_u = (_t = originalConfig.options) === null || _t === void 0 ? void 0 : _t.plugins) === null || _u === void 0 ? void 0 : _u.legend), { labels: __assign(__assign({}, (_x = (_w = (_v = originalConfig.options) === null || _v === void 0 ? void 0 : _v.plugins) === null || _w === void 0 ? void 0 : _w.legend) === null || _x === void 0 ? void 0 : _x.labels), { color: "black", font: {
                                            size: 20,
                                        } }) }) }) }),
                        plugins: originalConfig.plugins,
                    };
                    tempCtx.save();
                    tempChart = new ChartJS(tempCtx, clonedConfig);
                    return [4, tempChart.update()];
                case 1:
                    _y.sent();
                    tempCtx.restore();
                    link = document.createElement("a");
                    sanitizedChartKey = sanitizeKey(chartKey);
                    link.href = tempCanvas.toDataURL("image/png", 1.0);
                    link.download = "".concat(sanitizedChartKey, "-").concat(getCurrentTimestamp(), ".png");
                    link.click();
                    tempChart.destroy();
                    return [2];
            }
        });
    }); };
    return (React.createElement(Box, { sx: { padding: 0, position: "relative", minHeight: "100vh" } },
        React.createElement(Box, { sx: {
                position: "sticky",
                top: 0,
                zIndex: 1000,
                padding: 2,
                backgroundColor: "rgb(219, 228, 235)",
            } },
            React.createElement(MicroorganismSelect, { currentMicroorganism: currentMicroorganism, availableMicroorganisms: availableMicroorganisms, setCurrentMicroorganism: setCurrentMicroorganism })),
        loading ? (React.createElement(CircularProgress, null)) : (React.createElement(React.Fragment, null, Object.keys(chartData).length === 0 ? (React.createElement(Typography, { variant: "h6" }, t("No_data_available"))) : (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, spacing: 0 }, chartKeys.map(function (key) {
                var sanitizedKey = sanitizeKey(key);
                var refKey = "".concat(sanitizedKey, "-").concat(currentMicroorganism);
                if (!chartRefs.current[refKey]) {
                    chartRefs.current[refKey] =
                        React.createRef();
                }
                var isDisplayed = displayedChartsSet.has(key);
                return (React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6, key: refKey, sx: {
                        visibility: isDisplayed
                            ? "visible"
                            : "hidden",
                        height: isDisplayed
                            ? "auto"
                            : 0,
                        overflow: "hidden",
                    } },
                    React.createElement(ChartCard, { chartKey: key, chartData: chartData[key], chartRef: chartRefs.current[refKey], currentMicroorganism: currentMicroorganism, yearOptions: yearOptions, xAxisMax: xAxisMax, downloadChart: downloadChart, prevalenceUpdateDate: prevalenceUpdateDate })));
            })),
            React.createElement(Box, { sx: {
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1000,
                    padding: 2,
                    backgroundColor: "rgb(219, 228, 235)",
                } },
                React.createElement(Pagination, { count: totalPages, page: currentPage, onChange: function (_, value) {
                        return setCurrentPage(value);
                    }, sx: {
                        display: "flex",
                        justifyContent: "center",
                    } }))))))));
};
export { PrevalenceChart };
