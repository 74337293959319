export var italicWords = [
    "Salmonella",
    "coli",
    "E.",
    "Bacillus",
    "cereus",
    "monocytogenes",
    "Clostridioides",
    "difficile",
    "Yersinia",
    "Listeria",
    "enterocolitica",
    "Vibrio",
    "Baylisascaris",
    "procyonis",
    "Echinococcus",
    "Campylobacter",
];
export var formatMicroorganismNameArray = function (microName) {
    if (!microName) {
        console.warn("Received null or undefined microorganism name");
        return [];
    }
    var words = microName
        .split(/([-\s])/)
        .filter(function (part) { return part.length > 0; });
    return words.map(function (word) {
        if (word.trim() === "" || word === "-") {
            return { text: word, italic: false };
        }
        var italic = italicWords.some(function (italicWord) {
            return word.toLowerCase().includes(italicWord.toLowerCase());
        });
        return { text: word, italic: italic };
    });
};
export var getCurrentTimestamp = function () {
    var now = new Date();
    return now.toISOString().replace(/[-:.]/g, "");
};
export var getFormattedDate = function () {
    var now = new Date();
    return now.toLocaleDateString();
};
