var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ISOLATES_LINKS } from "../../shared/infrastructure/router/routes";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
var maxPageSize = 250;
var microorganism = [
    "CARBA-E. coli",
    "Campylobacter spp.",
    "E. coli",
    "ESBL/AmpC-E. coli",
    "Enterococcus spp.",
    "Listeria monocytogees",
    "MRSA",
    "STEC",
    "Salmonella spp.",
];
var animalSpeciesFoodTopCategory = [
    "Geflügel",
    "Huhn",
    "kleine Wiederkäuer",
    "Meeresfrüchte",
    "Nutztiere",
    "Pflanzliche Lebensmittel",
    "Pute",
    "Rehwild",
    "Rind",
    "Schwein",
    "Süßwasserfische",
];
var animalSpeciesProductionDirectionFood = [
    "Blattgemüse",
    "Cypriniden (Karpfenartige)",
    "Enten und Gänse",
    "Gemüse",
    "Getreide",
    "Huhn",
    "Kälber zur Mast (aufgezogen in Mastrinderbetrieben)",
    "Kälber zur Mast (aufgezogen in Milchviehbetrieben)",
    "Kälber zur Mast (für die Schlachtung mit spätestens 12 Monaten)",
    "Kräuter",
    "Krebstiere",
    "Lamm",
    "Läufer bis 30 kg",
    "Lebensmittel aus Blättern und Gräsern",
    "Legehennen",
    "Legehennen, konventionell",
    "Mastgeflügel",
    "Masthähnchen",
    "Masthähnchen, konventionell",
    "Masthähnchen, ökologisch",
    "Mastkalb/Jungrind",
    "Mastputen",
    "Mastputen, konventionell",
    "Mastputen, ökologisch",
    "Mastrind",
    "Mastschwein",
    "Mastschwein bis 50 kg",
    "Mastschwein, konventionell",
    "Mastschwein, ökologisch",
    "Milchrind",
    "Milchrind, konventionell",
    "Milchrind, ökologisch",
    "Nutztiere",
    "Obst",
    "Rehwild",
    "Rind",
    "Schaf und Ziege für Milchproduktion",
    "Tilapia und Pangasius",
    "Wiederkäuer",
    "Wiederkäuer für Milchproduktion",
    "Wildschwein",
    "Wildwiederkäuer",
    "Zuchthühner, Lege- und Mastlinie",
    "Zuchthühner, Legelinie",
    "Zuchthühner, Mastlinie",
    "Zuchtputen",
    "Zuchtsauen",
    "Zweischalige Weichtiere",
];
var matrix = [
    "Konsumeier, sortiert",
    "Frische Kräuter",
    "Sammelmilch",
    "Ölsaaten/Ölfruchte und Extraktionsschrote",
    "Petersilie",
    "Frisches Fleisch",
    "Blatt- und Kopfsalate",
    "Weizenmehl",
    "Sprossen",
    "(Hals),haut",
    "Schlachtkörper",
    "Kot/Staub",
    "Muskel",
    "Tatar/Schabefleisch",
    "Feldsalat, Rucola oder Pflücksalat",
    "Babyspinat",
    "Getrocknete Blatt- und Grasprodukte",
    "Rohmilchkäse",
    "Garnelen",
    "Kot",
    "Kiemeninhalt",
    "Eier, unsortiert",
    "Weichkäse und halbfester Schnittkäse",
    "Staub",
    "Alleinfuttermittel",
    "Erdbeeren",
    "Nasentupfer",
    "Streichfähige Rohwürste",
    "Muscheln",
    "Tomaten",
    "Blinddarminhalt",
    "Haut",
    "Nasenschleimhaut",
    "Hackfleisch",
    "Blattsalate",
];
var initialFilterSelection = {
    matrix: [],
    animalSpeciesProductionDirectionFood: [],
    animalSpeciesFoodTopCategory: [],
    microorganism: [],
};
function getTranslations(t) {
    var searchButtonText = t("Search");
    var filterButtonText = t("Filter");
    var heading = {
        main: "Linked Data Playground",
    };
    return {
        heading: heading,
        searchButtonText: searchButtonText,
        filterButtonText: filterButtonText,
    };
}
function toSelectionItem(stringItems, t) {
    return stringItems.map(function (item) { return ({
        value: item,
        displayName: t(item),
    }); });
}
var useLinkedDataPageComponent = function () {
    var t = useTranslation(["ExplanationPage"]).t;
    var _a = __read(useState([]), 2), data = _a[0], setData = _a[1];
    var availableOptions = {
        matrix: toSelectionItem(matrix, t),
        animalSpeciesProductionDirectionFood: toSelectionItem(animalSpeciesProductionDirectionFood, t),
        animalSpeciesFoodTopCategory: toSelectionItem(animalSpeciesFoodTopCategory, t),
        microorganism: toSelectionItem(microorganism, t),
    };
    var _b = getTranslations(t), heading = _b.heading, searchButtonText = _b.searchButtonText, filterButtonText = _b.filterButtonText;
    var _c = __read(useState(initialFilterSelection), 2), selectedFilters = _c[0], setSelectedFilters = _c[1];
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var camelToUnderscore = function (key) {
        var result = key.replace(/([A-Z])/g, " $1");
        return result.split(" ").join("_").toLowerCase();
    };
    var createQueryString = function (selection) {
        var query = [];
        Object.entries(selection).map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            if (value.length > 0) {
                var queryString = value
                    .map(function (v, index) {
                    return "filters[$or][" +
                        index +
                        "][" +
                        camelToUnderscore(key) +
                        "][name][$eq]=" +
                        t(v, { lng: "de" });
                })
                    .join("&");
                query.push(queryString);
            }
        });
        return query.join("&");
    };
    var fetchData = function (filter, contentType) {
        var options = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": contentType == "JSON"
                    ? "application/json"
                    : "application/ld+json",
            },
        };
        var qString = createQueryString(filter);
        setLoading(true);
        callApiService("".concat(ISOLATES_LINKS, "?locale=").concat(i18next.language, "&populate=*&").concat(qString, "&pagination[pageSize]=").concat(maxPageSize), options)
            .then(function (response) {
            var _a;
            var records = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data;
            records = records ? records : response.data;
            setData(records);
            setLoading(false);
            return data;
        })
            .catch(function (error) {
            setLoading(false);
            throw error;
        });
    };
    var availableFilters = [
        "otherDetail",
        "matrix",
        "animalSpeciesProductionDirectionFood",
        "animalSpeciesFoodTopCategory",
        "microorganism",
    ];
    var selectionConfig = availableFilters.map(function (filter) {
        return {
            label: t(filter.toUpperCase()),
            id: filter,
            selectedItems: selectedFilters[filter],
            selectionOptions: availableOptions[filter],
            handleChange: function (event) {
                var value = event.target.value;
                setSelectedFilters(function (prev) {
                    var newFilters = __assign({}, prev);
                    newFilters[filter] =
                        typeof value === "string"
                            ? value.split(",")
                            : value;
                    return newFilters;
                });
            },
        };
    });
    return {
        model: {
            searchButtonText: searchButtonText,
            filterButtonText: filterButtonText,
            heading: heading,
            data: data,
            selectionConfig: selectionConfig,
            selectedFilters: selectedFilters,
            loading: loading,
        },
        operations: {
            fetchData: fetchData,
        },
    };
};
export { useLinkedDataPageComponent };
