var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Typography, IconButton, Tooltip, Accordion, AccordionDetails, AccordionSummary, useTheme, } from "@mui/material";
import Markdown from "markdown-to-jsx";
export function ZNAccordion(props) {
    var _a = props.withTopBorder, withTopBorder = _a === void 0 ? true : _a;
    var _b = __read(useState(false), 2), tooltipOpen = _b[0], setTooltipOpen = _b[1];
    var theme = useTheme();
    var contentBox = props.centerContent ? (React.createElement(Box, { sx: {
            maxWidth: "fit-content",
            margin: "auto",
            boxSizing: "inherit",
        } }, props.content)) : (props.content);
    var copyToClipboard = function (text) {
        navigator.clipboard
            .writeText(text)
            .then(function () {
            setTooltipOpen(true);
            setTimeout(function () { return setTooltipOpen(false); }, 2000);
            return null;
        })
            .catch(function (err) {
            console.error("Failed to copy:", err);
        });
    };
    return (React.createElement(Accordion, { defaultExpanded: props.defaultExpanded, sx: __assign({ border: "none", boxShadow: "none", "&:before": {
                display: "none",
            }, "&.MuiAccordion-root.Mui-expanded": {
                margin: "1em 0",
            }, "& .MuiAccordionSummary-root": {
                margin: 0,
                borderBottom: "none",
            }, "& .MuiAccordionDetails-root": {
                padding: "16px 24px",
            } }, (withTopBorder && {
            "&:before": {
                display: "block",
                content: '""',
                width: "100%",
                height: "2px",
                backgroundColor: theme.palette.primary.main,
            },
        })) },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), "aria-controls": "shared-accordion-content", id: "shared-accordion-header", sx: {
                borderBottom: "none",
                margin: 0,
            } },
            React.createElement(Typography, { sx: {
                    flex: 1,
                    fontWeight: "bold",
                    fontSize: "1rem",
                    textAlign: "left",
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                } },
                React.createElement(Markdown, null, props.title),
                props.showCopyIcon && (React.createElement(Tooltip, { title: "Copy to Clipboard", open: tooltipOpen, disableFocusListener: true, disableTouchListener: true, placement: "top" },
                    React.createElement(IconButton, { onClick: function () { return copyToClipboard(props.title); }, onMouseEnter: function () { return setTooltipOpen(true); }, onMouseLeave: function () { return setTooltipOpen(false); }, size: "small", sx: { ml: 1 } },
                        React.createElement(ContentCopyIcon, { fontSize: "small" })))))),
        React.createElement(AccordionDetails, { sx: __assign({ marginLeft: "2em", marginRight: "2em", display: "block", hyphens: "auto", textAlign: "justify" }, (props.maxHeight && {
                maxHeight: props.maxHeight,
                overflow: "auto",
            })) }, contentBox)));
}
