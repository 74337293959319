import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { LinkPageLinkListComponent } from "./LinkPage-LinkList.component";
import { PageLayoutComponent } from "../../shared/components/layout/PageLayoutComponent";
export function LinkPageComponent() {
    var t = useTranslation(["ExternLinks"]).t;
    var theme = useTheme();
    return (React.createElement(PageLayoutComponent, null,
        React.createElement(Card, { sx: {
                padding: "1em",
                margin: "4rem auto",
                width: "50%",
                maxHeight: "calc(100vh - 140px)",
                overflowY: "auto",
            } },
            React.createElement(CardContent, null,
                React.createElement(Typography, { variant: "h1", sx: {
                        marginBottom: "1rem",
                        fontSize: "3rem",
                        textAlign: "center",
                        fontWeight: "normal",
                        color: theme.palette.primary.main,
                    } }, t("TextContent.Heading")),
                React.createElement(Typography, { sx: {
                        fontSize: "0.85rem",
                        lineHeight: "1.6",
                        textAlign: "justify",
                    } }, t("TextContent.Text")),
                React.createElement(LinkPageLinkListComponent, null)))));
}
