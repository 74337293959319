var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Suspense, useEffect, useState, forwardRef, } from "react";
import * as ReactDOM from "react-dom";
import { StyledEngineProvider, ThemeProvider, Snackbar, Alert as MuiAlert, } from "@mui/material";
import { LoadingProcessComponent } from "./shared/components/loading_process/LoadingProcess.component";
import { MainLayoutComponent } from "./shared/layout/Main-Layout.component";
import { znTheme } from "./shared/style/Style-MainTheme";
import { useTranslation } from "react-i18next";
import "../i18n";
var theme = znTheme;
var Alert = forwardRef(function (props, ref) { return (React.createElement(MuiAlert, __assign({ elevation: 6, ref: ref, variant: "filled" }, props))); });
function detectMobileDevice() {
    var userAgent = navigator.userAgent.toLowerCase();
    var isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(userAgent);
    return isMobile;
}
var App = function () {
    var t = useTranslation("ErrorPage").t;
    var _a = __read(useState(false), 2), openSnackbar = _a[0], setOpenSnackbar = _a[1];
    useEffect(function () {
        if (detectMobileDevice()) {
            setOpenSnackbar(true);
        }
    }, [t]);
    var handleCloseSnackbar = function (_event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MainLayoutComponent, null),
        React.createElement(Snackbar, { open: openSnackbar, anchorOrigin: { vertical: "top", horizontal: "center" }, onClose: handleCloseSnackbar },
            React.createElement(Alert, { onClose: handleCloseSnackbar, severity: "warning", sx: { width: "100%" } }, t("SmartPhone Detected")))));
};
ReactDOM.render(React.createElement(StyledEngineProvider, { injectFirst: true },
    React.createElement(ThemeProvider, { theme: theme },
        React.createElement(Suspense, { fallback: React.createElement(LoadingProcessComponent, null) },
            React.createElement(App, null)))), document.querySelector("#application"));
