var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { List, ListSubheader } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import { ListContentListItemComponent } from "./ListContent-ListItem.component";
import { EXTERNAL_LINKS } from "../../shared/infrastructure/router/routes";
export function LinkPageLinkListComponent() {
    var t = useTranslation(["ExternLinks"]).t;
    var _a = __read(useState([]), 2), linkData = _a[0], setLinkData = _a[1];
    useEffect(function () {
        var apiEndpoint = "".concat(EXTERNAL_LINKS, "?locale=").concat(i18next.language);
        callApiService(apiEndpoint)
            .then(function (response) {
            if (response.data && response.data.data) {
                var extractedLinks = response.data.data.map(function (item) { return item.attributes; });
                var sortedLinks = extractedLinks.sort(function (a, b) { return b.priority - a.priority; });
                setLinkData(sortedLinks);
            }
            return null;
        })
            .catch(function (error) {
            console.error("Error fetching data: ", error);
        });
    }, [i18next.language]);
    var groupByCategory = function (links) {
        return links.reduce(function (acc, link) {
            if (!acc[link.category])
                acc[link.category] = [];
            acc[link.category].push(link);
            return acc;
        }, {});
    };
    var groupedLinks = groupByCategory(linkData);
    return (React.createElement("div", null, Object.entries(groupedLinks).map(function (_a) {
        var _b = __read(_a, 2), category = _b[0], links = _b[1];
        return (React.createElement(List, { key: category },
            React.createElement(ListSubheader, null, t("".concat(category), category)),
            links.map(function (link, index) { return (React.createElement(ListContentListItemComponent, { key: "Link".concat(index), link: link.link, text: link.name })); })));
    })));
}
