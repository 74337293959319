var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Box, Button, Tooltip } from "@mui/material";
import Markdown from "markdown-to-jsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ZNAccordion } from "../../shared/components/accordion/ZNAccordion";
import { FilterMultiSelectionComponent } from "./FilterMultiSelectionComponent";
export function FilterContainerComponent(_a) {
    var selectionConfig = _a.selectionConfig, howToContent = _a.howToContent, howToHeading = _a.howToHeading;
    var _b = useTranslation(["ExplanationPage"]), t = _b.t, i18n = _b.i18n;
    var _c = __read(useState(null), 2), tooltipOpen = _c[0], setTooltipOpen = _c[1];
    var handleResetFilters = function () {
        selectionConfig.forEach(function (config) {
            var event = { target: { value: [] } };
            config.handleChange(event);
        });
    };
    var handleApplyAllFilters = function () {
        selectionConfig.forEach(function (config) {
            var event = {
                target: {
                    value: config.selectionOptions.map(function (option) { return option.value; }),
                },
            };
            config.handleChange(event);
        });
    };
    return (React.createElement(Box, { key: i18n.language, sx: {
            display: "flex",
            flexDirection: "column",
            margin: "2em auto",
            maxHeight: "calc(100vh - 140px)",
            overflowY: "auto",
        } },
        selectionConfig.map(function (config) {
            var selectedItemsTranslated = config.selectedItems.map(function (item) { return t(item); }).join(", ") ||
                t("None");
            var tooltipTitle = "".concat(t(config.label), ": ").concat(selectedItemsTranslated);
            return (React.createElement(Tooltip, { title: tooltipTitle, key: config.id, open: tooltipOpen === config.id, onClose: function () { return setTooltipOpen(null); }, disableHoverListener: tooltipOpen !== null },
                React.createElement(Box, { sx: {
                        display: "flex",
                        flexDirection: "row",
                        padding: 1,
                        justifyContent: "space-between",
                        gap: 2,
                    }, onMouseEnter: function () { return setTooltipOpen(config.id); }, onMouseLeave: function () { return setTooltipOpen(null); } },
                    React.createElement(FilterMultiSelectionComponent, { name: config.id, selectedItems: config.selectedItems, selectionOptions: config.selectionOptions, label: t(config.label), actions: {
                            handleChange: config.handleChange,
                        } }))));
        }),
        React.createElement(Button, { onClick: handleResetFilters, variant: "contained", sx: {
                mb: 2,
                width: "180px",
                minWidth: "0",
                padding: "6px 16px",
                fontSize: "0.700rem",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
            } }, t("Delete All Filters")),
        React.createElement(Button, { onClick: handleApplyAllFilters, variant: "contained", color: "primary", sx: {
                mb: 2,
                width: "180px",
                minWidth: "0",
                padding: "6px 16px",
                fontSize: "0.700rem",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
            } }, t("Apply All Filters")),
        React.createElement(ZNAccordion, { key: "howTo", title: howToHeading, content: React.createElement(Markdown, null, howToContent), defaultExpanded: true, centerContent: false })));
}
