var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { pageRoute } from "../../infrastructure/router/routes";
import { headerHeight, onPrimaryColor, onSecondaryColor, primaryColor, secondaryColor, } from "../../style/Style-MainTheme";
import { TranslationButtonsComponent } from "./TranslationButtons.component";
var headerStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    height: ", "px;\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n"], ["\n    width: 100%;\n    height: ", "px;\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n"])), headerHeight);
var mainHeaderStyle = function () { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    box-sizing: border-box;\n    box-shadow: \"0 8px 6px -6px grey\";\n    background-color: ", ";\n    color: ", ";\n"], ["\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    box-sizing: border-box;\n    box-shadow: \"0 8px 6px -6px grey\";\n    background-color: ", ";\n    color: ", ";\n"])), primaryColor, onSecondaryColor); };
var appNameStyle = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 0.5em 1em 0.5em 1em;\n    font-size: 1.2rem;\n    text-decoration: none;\n    color: ", ";\n    &:focus {\n        outline: none;\n    }\n"], ["\n    padding: 0.5em 1em 0.5em 1em;\n    font-size: 1.2rem;\n    text-decoration: none;\n    color: ", ";\n    &:focus {\n        outline: none;\n    }\n"])), onPrimaryColor);
var navLinkStyle = function (open) { return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 0.5em 1em 0.5em 1em;\n    font-size: 1rem;\n    text-decoration: none;\n    background-color: ", ";\n    color: ", ";\n    &:focus {\n        outline: none;\n    }\n    &:hover {\n        color: ", ";\n    }\n"], ["\n    padding: 0.5em 1em 0.5em 1em;\n    font-size: 1rem;\n    text-decoration: none;\n    background-color: ", ";\n    color: ", ";\n    &:focus {\n        outline: none;\n    }\n    &:hover {\n        color: ", ";\n    }\n"])), open ? "".concat(secondaryColor) : "none", open ? "".concat(onSecondaryColor) : "".concat(onPrimaryColor), open ? "".concat(onSecondaryColor) : "".concat(secondaryColor)); };
var leftHeaderStyle = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n"], ["\n    display: flex;\n    flex-direction: row;\n"])));
var rightHeaderStyle = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    height: 100%;\n    margin-right: 8em;\n    display: flex;\n    align-items: flex-end;\n"], ["\n    height: 100%;\n    margin-right: 8em;\n    display: flex;\n    align-items: flex-end;\n"])));
export function HeaderComponent() {
    var _a = __read(useState(false), 2), linkOpen = _a[0], setLinkOpen = _a[1];
    var _b = __read(useState(false), 2), linkedDataOpen = _b[0], setLinkedDataOpen = _b[1];
    var _c = __read(useState(false), 2), infoOpen = _c[0], setInfoOpen = _c[1];
    var _d = __read(useState(false), 2), evaluationsOpen = _d[0], setEvaluationsOpen = _d[1];
    var _e = __read(useState(false), 2), prevalenceOpen = _e[0], setPrevalenceOpen = _e[1];
    var t = useTranslation(["Header"]).t;
    var showLD = process.env.REACT_APP_SHOW_LD === "true";
    var pathname = useLocation().pathname;
    useEffect(function () {
        if (pathname === pageRoute.infoPagePath) {
            setInfoOpen(true);
        }
        else {
            setInfoOpen(false);
        }
        if (pathname === pageRoute.linkPagePath) {
            setLinkOpen(true);
        }
        else {
            setLinkOpen(false);
        }
        if (pathname === pageRoute.evaluationsPagePath) {
            setEvaluationsOpen(true);
        }
        else {
            setEvaluationsOpen(false);
        }
        if (pathname === pageRoute.prevalencePagePath) {
            setPrevalenceOpen(true);
        }
        else {
            setPrevalenceOpen(false);
        }
        if (pathname === pageRoute.linkedDataPagePath) {
            setLinkedDataOpen(true);
        }
        else {
            setLinkedDataOpen(false);
        }
    });
    return (_jsx("header", __assign({ css: headerStyle }, { children: _jsxs("div", __assign({ css: mainHeaderStyle() }, { children: [_jsxs("div", __assign({ css: leftHeaderStyle }, { children: [_jsx(NavLink, __assign({ to: pageRoute.homePagePath, css: appNameStyle }, { children: "ZooNotify" })), _jsx(TranslationButtonsComponent, {})] })), _jsxs("div", __assign({ css: rightHeaderStyle }, { children: [_jsx(NavLink, __assign({ to: pageRoute.infoPagePath, css: navLinkStyle(infoOpen) }, { children: t("Info") })), _jsx(NavLink, __assign({ to: pageRoute.evaluationsPagePath, css: navLinkStyle(evaluationsOpen) }, { children: t("Evaluations") })), _jsx(NavLink, __assign({ to: pageRoute.prevalencePagePath, css: navLinkStyle(prevalenceOpen) }, { children: t("prevalence") })), _jsx(NavLink, __assign({ to: pageRoute.linkPagePath, css: navLinkStyle(linkOpen) }, { children: t("Links") })), showLD && (_jsx(NavLink, __assign({ to: pageRoute.linkedDataPagePath, css: navLinkStyle(linkedDataOpen) }, { children: "LD" })))] }))] })) })));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
