var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Box, Button, Card, CardContent, CardMedia, Link } from "@mui/material";
import { useTheme } from "@mui/system";
import { footerHeight, headerHeight, } from "./../../shared/style/Style-MainTheme";
import Markdown from "markdown-to-jsx";
import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
export function EvaluationsCardComponent(props) {
    var theme = useTheme();
    var _a = __read(useState(false), 2), isViewerOpen = _a[0], setIsViewerOpen = _a[1];
    var openImageViewer = useCallback(function () {
        setIsViewerOpen(true);
    }, []);
    var closeImageViewer = function () {
        setIsViewerOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { "data-id": props.id, sx: {
                display: "flex",
                flexDirection: ["column", "row"],
                borderRadius: 0,
                boxShadow: 0,
            } },
            React.createElement(Box, { sx: {
                    display: "flex",
                    flexDirection: "column",
                    width: ["100%", "50%"],
                } },
                React.createElement(CardContent, { sx: {
                        flex: "1 0 auto",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    } },
                    React.createElement(Markdown, null, props.description))),
            React.createElement(Box, { sx: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: ["100%", "50%"],
                } },
                React.createElement(CardMedia, { component: "img", sx: { cursor: "pointer" }, image: props.chartPath, alt: props.title, onClick: openImageViewer }),
                React.createElement(Box, { sx: {
                        display: "flex",
                        flexDirection: ["column", "row"],
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        mt: 2,
                    } },
                    React.createElement(Button, { color: "primary", variant: "contained", sx: {
                            margin: "0.5em",
                            padding: "0em",
                            backgroundColor: theme.palette.primary.main,
                            "@media (max-width: 450px)": {
                                fontSize: "0.7em",
                            },
                            "@media (max-width: 350px)": {
                                fontSize: "0.6em",
                            },
                        } },
                        React.createElement(Link, { href: props.dataPath, download: true, sx: {
                                width: "100%",
                                padding: "0.5em 1em",
                                color: "inherit",
                                textDecoration: "none",
                            } }, props.downloadDataButtonText)),
                    React.createElement(Button, { color: "primary", variant: "contained", sx: {
                            margin: "0.5em",
                            padding: "0em",
                            backgroundColor: theme.palette.primary.main,
                            "@media (max-width: 450px)": {
                                fontSize: "0.7em",
                            },
                            "@media (max-width: 350px)": {
                                fontSize: "0.6em",
                            },
                        } },
                        React.createElement(Link, { href: props.chartPath, download: true, sx: {
                                width: "100%",
                                padding: "0.5em 1em",
                                color: "inherit",
                                textDecoration: "none",
                            } }, props.downloadGraphButtonText))))),
        isViewerOpen && (React.createElement(ImageViewer, { src: [props.chartPath], currentIndex: 0, onClose: closeImageViewer, disableScroll: true, backgroundStyle: {
                backgroundColor: "rgba(0,0,0,0.9)",
                maxHeight: "calc(100vh - ".concat(footerHeight, "px - ").concat(headerHeight, "px)"),
                top: "".concat(headerHeight, "px"),
                zIndex: "105",
            }, closeOnClickOutside: true }))));
}
