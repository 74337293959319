export var pageRoute = {
    homePagePath: "/",
    linkPagePath: "/links",
    infoPagePath: "/explanations",
    evaluationsPagePath: "/evaluations",
    dpdPagePath: "/dataProtectionDeclaration",
    linkedDataPagePath: "/ld",
    prevalencePagePath: "/prevalence",
};
var env = process.env.REACT_APP_ENV;
var cms = env === "qa" || env === "prod" ? "/cms" : "";
export var CMS_BASE_ENDPOINT = process.env.REACT_APP_API_URL;
export var CMS_API_ENDPOINT = "".concat(CMS_BASE_ENDPOINT, "/api");
export var API_DOCUMENTATION_URL = "".concat(CMS_BASE_ENDPOINT).concat(cms, "/documentation/v2.2.0");
export var CONFIGURATION = "".concat(CMS_API_ENDPOINT, "/configuration");
export var WELCOME = "".concat(CMS_API_ENDPOINT, "/welcome");
export var EXPLANATION = "".concat(CMS_API_ENDPOINT, "/explanations");
export var EVALUATIONS = "".concat(CMS_API_ENDPOINT, "/evaluations");
export var PREVALENCES = "".concat(CMS_API_ENDPOINT, "/prevalences");
export var EVALUATION_INFO = "".concat(CMS_API_ENDPOINT, "/evaluation-information");
export var AMR_TABLE = "".concat(CMS_API_ENDPOINT, "/resistance-tables?populate[0]=cut_offs&populate[1]=cut_offs.antibiotic");
export var EXTERNAL_LINKS = "".concat(CMS_API_ENDPOINT, "/externallinks");
export var ISOLATES_LINKS = "".concat(CMS_API_ENDPOINT, "/isolates");
export var INFORMATION = "".concat(CMS_API_ENDPOINT, "/informations");
export var DATA_PROTECTION = "".concat(CMS_API_ENDPOINT, "/data-protection-declaration");
export var SAMPLE_ORIGINS = "".concat(CMS_API_ENDPOINT, "/sample-origins");
export var SUPER_CATEGORY_SAMPLE_ORIGINS = "".concat(CMS_API_ENDPOINT, "/super-category-sample-origins");
export var MATRICES = "".concat(CMS_API_ENDPOINT, "/matrices");
export var MATRIX_GROUPS = "".concat(CMS_API_ENDPOINT, "/matrix-groups");
export var SAMPLING_STAGES = "".concat(CMS_API_ENDPOINT, "/sampling-stages");
export var MICROORGANISMS = "".concat(CMS_API_ENDPOINT, "/microorganisms");
