import { useTranslation } from "react-i18next";
var usePrevalencePageComponent = function () {
    var t = useTranslation(["PrevalencePage"]).t;
    return {
        model: {
            sideBarTitle: t("SEARCH_SETTINGS"),
            mainHeading: t("PREVALENCE"),
        },
    };
};
export { usePrevalencePageComponent };
