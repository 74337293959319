import { useTranslation } from "react-i18next";
import { useEvaluationData } from "./EvaluationDataContext";
var useEvaluationContentComponent = function () {
    var t = useTranslation(["ExplanationPage"]).t;
    var evaluationContext = useEvaluationData();
    return {
        model: {
            downloadDataButtonText: t("Data_Download"),
            downloadGraphButtonText: t("Export"),
            heading: t("Heading"),
            evaluationsData: evaluationContext.evaluationsData,
            loading: evaluationContext.isLoading,
        },
        operations: {
            showDivision: evaluationContext.showDivision,
        },
    };
};
export { useEvaluationContentComponent };
