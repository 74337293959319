import React from "react";
import { Box, Alert, Link } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { MainComponentHeader } from "../../shared/components/MainComponentHeader";
import { usePrevalenceFilters } from "./PrevalenceDataContext";
import { PrevalenceDataGrid } from "./PrevalenceDataGrid";
import { useFetchSupportEmail } from "../../shared/components/footer/Footer-Container.component";
var PrevalenceMainContent = function (_a) {
    var heading = _a.heading;
    var _b = usePrevalenceFilters(), prevalenceData = _b.prevalenceData, loading = _b.loading, error = _b.error, showError = _b.showError;
    var theme = useTheme();
    var t = useTranslation(["PrevalencePage"]).t;
    var supportMail = useFetchSupportEmail();
    var mailtoLink = supportMail && error
        ? "mailto:".concat(supportMail, "?subject=ZooNotify-Error-Report&body=").concat(encodeURIComponent(error))
        : "";
    return (React.createElement(React.Fragment, null,
        React.createElement(MainComponentHeader, { heading: heading }),
        React.createElement(Box, { sx: {
                pt: theme.spacing(3),
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                height: "calc(100vh - 150px)",
                overflow: "auto",
                justifyContent: "flex-start",
                padding: theme.spacing(2),
            } },
            showError && error && supportMail && (React.createElement(Alert, { severity: "error" },
                t("errorPrefix"),
                " ",
                React.createElement(Link, { href: mailtoLink }, t("errorLinkText")),
                " ",
                t("errorSuffix"))),
            prevalenceData.length > 0 && (React.createElement(Box, { sx: { width: "95%" } },
                React.createElement(PrevalenceDataGrid, { prevalenceData: prevalenceData, loading: loading }))))));
};
export { PrevalenceMainContent };
