import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
export function EvaluationDivisionHeaderComponent(_a) {
    var divisionTitle = _a.divisionTitle;
    var theme = useTheme();
    return (React.createElement(Typography, { sx: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: "0.5em",
            margin: "1em 0",
        }, id: divisionTitle }, divisionTitle));
}
