var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Markdown from "markdown-to-jsx";
import React from "react";
import { ZNAccordion } from "../../shared/components/accordion/ZNAccordion";
import { ExplanationTermComponent } from "../components/ExplanationTermComponent copy";
import { InfoPageAmrDialogComponent } from "../components/InfoPage-AmrsDialog.component";
import { useExplanationPageComponent } from "./explanationUseCases";
import { PageLayoutComponent } from "../../shared/components/layout/PageLayoutComponent";
export function InfoPageComponent(props) {
    var _a = useExplanationPageComponent(null), model = _a.model, operations = _a.operations;
    var theme = useTheme();
    var subHeadingStyle = {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: "0.5em",
        margin: "1em 0",
    };
    var handleExportAmrData = function (amrKey) {
        props.onAmrDataExport(amrKey);
    };
    return (React.createElement(PageLayoutComponent, null,
        React.createElement(Box, { sx: {
                width: "60%",
                maxHeight: "calc(100vh - 140px)",
                overflowY: "auto",
                margin: "2em auto",
            } },
            React.createElement(Typography, { variant: "h1", sx: {
                    marginBottom: "1rem",
                    paddingBottom: "0.5em",
                    fontSize: "3rem",
                    textAlign: "center",
                    fontWeight: "normal",
                    color: theme.palette.primary.main,
                    borderBottom: "1px solid ".concat(theme.palette.primary.main),
                } }, model.title),
            React.createElement("div", null,
                model.mainSection.length > 0 ? (React.createElement(ZNAccordion, { key: "mainSection", title: model.mainSection[0].title, content: React.createElement(Markdown, null, model.mainSection[0].description), defaultExpanded: false, centerContent: false })) : null,
                Object.entries(model.explanationCollection).length > 0
                    ? Object.entries(model.explanationCollection).map(function (_a) {
                        var _b = __read(_a, 2), sectionToken = _b[0], explanations = _b[1];
                        return (React.createElement("div", { key: sectionToken },
                            React.createElement(Typography, { sx: subHeadingStyle, id: sectionToken }, sectionToken),
                            React.createElement("div", null, explanations.map(function (explanation) {
                                return (React.createElement(ZNAccordion, { key: explanation.title, title: explanation.title, content: React.createElement(ExplanationTermComponent, { handleOpen: operations.handleOpen, description: explanation.description }), defaultExpanded: false, centerContent: false }));
                            }))));
                    })
                    : null,
                model.openAmrDialog && model.currentAMRID ? (React.createElement(InfoPageAmrDialogComponent, { resistancesTableData: props.tableData[model.currentAMRID], onClose: operations.handleClose, onAmrDataExport: function () {
                        return handleExportAmrData(model.currentAMRID);
                    } })) : null))));
}
