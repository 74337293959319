import { makeStyles } from "@mui/styles";
var useStyles = makeStyles(function (theme) { return ({
    formControl: {
        margin: theme.spacing(1),
        width: 300,
    },
    indeterminateColor: {
        color: "#f50057",
    },
    selectAllText: {
        fontWeight: 500,
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
}); });
export { useStyles };
