import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Typography, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormattedMicroorganismName } from "./FormattedMicroorganismName";
var MicroorganismSelect = function (_a) {
    var currentMicroorganism = _a.currentMicroorganism, availableMicroorganisms = _a.availableMicroorganisms, setCurrentMicroorganism = _a.setCurrentMicroorganism;
    var t = useTranslation(["PrevalencePage"]).t;
    return (React.createElement(FormControl, { fullWidth: true, sx: { mb: 1 }, variant: "outlined" },
        React.createElement(InputLabel, { id: "microorganism-select-label", shrink: true }, t("Select_Microorganism")),
        React.createElement(Select, { labelId: "microorganism-select-label", value: currentMicroorganism || "", onChange: function (event) {
                return setCurrentMicroorganism(event.target.value);
            }, label: t("Select_Microorganism"), sx: { backgroundColor: "#f5f5f5" }, renderValue: function (selected) { return (React.createElement(FormattedMicroorganismName, { microName: selected })); } }, availableMicroorganisms.length > 0 ? (availableMicroorganisms.map(function (microorganism) { return (React.createElement(MenuItem, { key: microorganism, value: microorganism },
            React.createElement(FormattedMicroorganismName, { microName: microorganism }))); })) : (React.createElement(MenuItem, { disabled: true },
            React.createElement(Typography, { component: "span" }, t("No_Microorganisms_Available")))))));
};
export { MicroorganismSelect };
