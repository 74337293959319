import { Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { environment } from "../../../../environment";
export function LastUpdateDateComponent() {
    var lastChange = environment.lastChange;
    var t = useTranslation(["Footer"]).t;
    var dateLayout = t("Date.Layout");
    var theme = useTheme();
    var dateStyle = {
        margin: 0,
        marginLeft: "15px",
        padding: 0,
        lineHeight: "1.05",
        fontSize: "0.75rem",
        color: theme.palette.text.primary,
    };
    return (React.createElement(Box, { component: "p", sx: dateStyle },
        React.createElement(Typography, { variant: "caption" },
            t("Date.Text"),
            " ",
            moment(lastChange, moment.ISO_8601, dateLayout, true).format("DD.MM.YYYY"))));
}
