import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import React from "react";
export function MainComponentHeader(_a) {
    var heading = _a.heading;
    var theme = useTheme();
    return (React.createElement(Typography, { variant: "h1", sx: {
            paddingBottom: "0.2em",
            fontSize: "3rem",
            textAlign: "center",
            fontWeight: "normal",
            color: theme.palette.primary.main,
            borderBottom: "1px solid ".concat(theme.palette.primary.main),
        } }, heading));
}
