var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Box } from "@mui/material";
import React, { useState } from "react";
import { MainContentComponent } from "./MainContentComponent";
import { SidebarComponent } from "./SidebarComponent";
export var MainWithSideLayout = function (_a) {
    var side = _a.side, main = _a.main, sidebarTitle = _a.sidebarTitle;
    var _b = __read(useState(true), 2), isOpen = _b[0], setIsOpen = _b[1];
    var handleOpenClick = function () {
        setIsOpen(!isOpen);
    };
    return (React.createElement(Box, { style: {
            display: "flex",
            width: "100vw",
            height: "100vh",
            maxHeight: "calc(100vh )",
        } },
        React.createElement(SidebarComponent, { isOpen: isOpen, handleOpenClick: handleOpenClick, title: sidebarTitle }, side),
        React.createElement(MainContentComponent, null, main)));
};
