import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import { FilterMultiSelectionComponent } from "./FilterMultiSelectionComponent";
export function FilterContainerComponent(_a) {
    var selectionConfig = _a.selectionConfig, searchButtonText = _a.searchButtonText, loading = _a.loading, handleSearchBtnClick = _a.handleSearchBtnClick;
    var getFilterById = function (id) {
        var result = selectionConfig.filter(function (config) {
            return config.id == id;
        });
        if (result && result.length > 0) {
            var selectedItems = result[0].selectedItems;
            if (selectedItems.includes("")) {
                selectedItems.splice(selectedItems.indexOf(""), 1);
            }
            return selectedItems;
        }
        else
            return [];
    };
    var handleSearch = function () {
        var filter = {
            matrix: getFilterById("matrix"),
            animalSpeciesProductionDirectionFood: getFilterById("animalSpeciesProductionDirectionFood"),
            animalSpeciesFoodTopCategory: getFilterById("animalSpeciesFoodTopCategory"),
            microorganism: getFilterById("microorganism"),
        };
        handleSearchBtnClick(filter);
    };
    return (React.createElement(Box, { sx: { display: "flex", flexDirection: "column" } },
        React.createElement(Box, { sx: {
                display: "flex",
                flexDirection: "row",
                padding: 1,
                justifyContent: "space-between",
                gap: 2,
            } }, selectionConfig.slice(1, 3).map(function (config) { return (React.createElement(FilterMultiSelectionComponent, { key: config.label, name: config.id, selectedItems: config.selectedItems, selectionOptions: config.selectionOptions, label: config.label, actions: {
                handleChange: config.handleChange,
            } })); })),
        React.createElement(Box, { sx: {
                display: "flex",
                flexDirection: "row",
                padding: 1,
                justifyContent: "space-between",
                gap: 2,
            } }, selectionConfig.slice(3, 5).map(function (config) { return (React.createElement(FilterMultiSelectionComponent, { name: config.id, key: config.label, selectedItems: config.selectedItems, selectionOptions: config.selectionOptions, label: config.label, actions: {
                handleChange: config.handleChange,
            } })); })),
        React.createElement(Box, { sx: {
                display: "flex",
                flexDirection: "row",
                padding: 1,
                justifyContent: "space-between",
                gap: 2,
            } },
            React.createElement(Button, { variant: "outlined", disabled: loading, onClick: function () {
                    handleSearch();
                }, startIcon: loading ? (React.createElement(React.Fragment, null,
                    React.createElement(SearchIcon, null),
                    React.createElement(CircularProgress, { size: 24, sx: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        } }))) : (React.createElement(SearchIcon, null)), sx: {
                    margin: "8px",
                } }, searchButtonText))));
}
