var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Markdown from "markdown-to-jsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AMR_TABLE } from "../../shared/infrastructure/router/routes";
import { InfoPageComponent } from "./ExplanationMainComponent";
import { ErrorSnackbar } from "../../shared/components/ErrorSnackbar/ErrorSnackbar";
var InfoPageContainer = function () {
    var _a = __read(useState({}), 2), amrTableData = _a[0], setAmrTableData = _a[1];
    var _b = __read(useState(true), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(null), 2), error = _c[0], setError = _c[1];
    var t = useTranslation(["InfoPage"]).t;
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, json, transformedData, fetchError_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        setLoading(true);
                        return [4, fetch(AMR_TABLE)];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error("HTTP error! status: ".concat(response.status));
                        }
                        return [4, response.json()];
                    case 2:
                        json = _a.sent();
                        transformedData = json.data.reduce(function (acc, item) {
                            var _a = item.attributes, tableId = _a.table_id, description = _a.description, title = _a.title, cutOffs = _a.cut_offs;
                            var years = cutOffs
                                .reduce(function (yearList, rec) {
                                if (yearList.findIndex(function (x) { return x == rec.year.toString(); }) == -1) {
                                    yearList.push(rec.year.toString());
                                }
                                return yearList;
                            }, [])
                                .sort(function (a, b) { return b.localeCompare(a); });
                            var antibiotics = cutOffs.reduce(function (microbs, rec) {
                                if (microbs.findIndex(function (x) {
                                    return x ==
                                        rec.antibiotic.data.attributes.shortName;
                                }) == -1) {
                                    microbs.push(rec.antibiotic.data.attributes.shortName);
                                }
                                return microbs;
                            }, []);
                            var tableTitle = "Table ".concat(tableId, ": ").concat(title);
                            acc[tableId] = {
                                introduction: React.createElement("div", null, description),
                                title: React.createElement(Markdown, null, tableTitle),
                                titleString: tableTitle,
                                description: description,
                                tableHeader: __spreadArray([
                                    t("Methods.Amrs.TableHeaderShortSub"),
                                    t("Methods.Amrs.TableHeaderClass"),
                                    t("Methods.Amrs.TableHeaderSubstance")
                                ], __read(years), false),
                                tableSubHeader: years.reduce(function (subAcc, year) {
                                    var _a;
                                    return (__assign(__assign({}, subAcc), (_a = {}, _a[year] = ["Min", "Max", "Cut-off"], _a)));
                                }, {}),
                                tableRows: antibiotics.map(function (antibiotic) {
                                    var antibioticName = "", antibioticShortName = "", substanceClass = "";
                                    var concentrationList = years.reduce(function (listAcc, year) {
                                        var antibioticData = cutOffs.find(function (rec) {
                                            return rec.year.toString() == year &&
                                                rec.antibiotic.data.attributes
                                                    .shortName == antibiotic;
                                        });
                                        if (antibioticData) {
                                            antibioticName =
                                                antibioticData.antibiotic.data
                                                    .attributes.name;
                                            antibioticShortName =
                                                antibioticData.antibiotic.data
                                                    .attributes.shortName;
                                            substanceClass =
                                                antibioticData.substanzklasse;
                                            listAcc[year] = {
                                                cutOff: antibioticData.cutOff.toString(),
                                                min: antibioticData.min.toString(),
                                                max: antibioticData.max.toString(),
                                            };
                                        }
                                        return listAcc;
                                    }, {});
                                    return {
                                        amrSubstance: antibioticShortName,
                                        substanceClass: substanceClass,
                                        wirkstoff: antibioticName,
                                        shortSubstance: antibioticShortName,
                                        concentrationList: concentrationList,
                                    };
                                }),
                            };
                            return acc;
                        }, {});
                        setAmrTableData(transformedData);
                        setLoading(false);
                        return [3, 4];
                    case 3:
                        fetchError_1 = _a.sent();
                        console.error("Error fetching data:", fetchError_1);
                        setError(fetchError_1 instanceof Error
                            ? fetchError_1.message
                            : "unknownError");
                        setLoading(false);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        }); };
        fetchData();
    }, []);
    var handleCloseError = function () {
        setError(null);
    };
    if (loading) {
        return React.createElement("div", null, t(" "));
    }
    var handleExportAmrData = function (amrKey) {
        console.log("Export functionality for", amrKey);
    };
    return (React.createElement("div", null,
        React.createElement(InfoPageComponent, { tableData: amrTableData, onAmrDataExport: handleExportAmrData }),
        error && (React.createElement(ErrorSnackbar, { open: !!error, handleClose: handleCloseError }))));
};
export { InfoPageContainer };
