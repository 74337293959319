var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Paper, styled } from "@mui/material";
import React from "react";
var Item = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({ backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff" }, theme.typography.body2), { padding: theme.spacing(1), textAlign: "center", color: theme.palette.text.secondary }));
});
export var MainContentComponent = function (_a) {
    var children = _a.children;
    return (React.createElement(Item, { sx: {
            overflow: "auto",
            width: "100%",
            height: "100%",
            marginLeft: "20px",
            boxShadow: "15px 0 15px -15px inset",
        } },
        React.createElement(Box, null, children)));
};
