import React from "react";
import { Box, useTheme } from "@mui/system";
import { footerHeight } from "./../../../shared/style/Style-MainTheme";
export function FooterLayoutComponent(props) {
    var theme = useTheme();
    return (React.createElement(Box, { component: "footer", sx: {
            width: "100%",
            height: "".concat(footerHeight, "px"),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box",
            backgroundColor: theme.palette.background.paper,
            borderTop: "2px solid ".concat(theme.palette.primary.main),
        } },
        props.lastUpdateComponent,
        props.linkListComponent));
}
