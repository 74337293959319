import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
export function LogoComponent() {
    var t = useTranslation(["HomePage"]).t;
    return (React.createElement(Box, null,
        React.createElement(Typography, { sx: {
                fontSize: "0.85rem",
                lineHeight: "1.6",
                textAlign: "justify",
            } }, t("Initative")),
        React.createElement("img", { src: "/assets/bfr_logo.png", title: "BfR Logo", style: {
                height: "60px",
                width: "auto",
                padding: "25px",
                backgroundColor: "white",
            } })));
}
