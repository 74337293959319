import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
export function LoadingProcessComponent() {
    var theme = useTheme();
    return (React.createElement(Box, { sx: {
            display: "flex",
            "& > * + *": {
                marginLeft: theme.spacing(2),
            },
        } },
        React.createElement(CircularProgress, { sx: { margin: "3em auto", color: theme.palette.primary.main } })));
}
