import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ZNAccordion } from "../../shared/components/accordion/ZNAccordion";
import { EvaluationsCardComponent } from "./EvaluationCardComponent";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
export function EvaluationDivisionDataComponent(_a) {
    var data = _a.data, downloadGraphButtonText = _a.downloadGraphButtonText, downloadDataButtonText = _a.downloadDataButtonText;
    var t = useTranslation(["ExplanationPage"]).t;
    return (React.createElement("div", null,
        data &&
            data.length > 0 &&
            data.map(function (evaluation) { return (React.createElement(ZNAccordion, { key: "accordion-".concat(uuidv4()), title: evaluation.title, showCopyIcon: true, content: React.createElement(EvaluationsCardComponent, { title: evaluation.title, id: evaluation.id, description: evaluation.description, chartPath: evaluation.chartPath, dataPath: evaluation.dataPath, downloadGraphButtonText: downloadGraphButtonText, downloadDataButtonText: downloadDataButtonText }), defaultExpanded: false, centerContent: true })); }),
        data && data.length == 0 && (React.createElement("div", { style: { height: "100%" } },
            React.createElement(Typography, { variant: "h3" }, t("No_Records"))))));
}
