import { createTheme, responsiveFontSizes } from "@mui/material/styles";
var bfrBlue = "rgb(0, 56, 105)";
var bfrWhite = "rgb(255, 255, 255)";
var bfrLightgrey = "rgb(219, 228, 235)";
var bfrLightBlue = "rgb(186, 216, 233)";
var bfrBlack = "rgb(0, 0, 0)";
var bfrRed = "rgb(228, 0, 56)";
export var primaryColor = bfrBlue;
export var secondaryColor = bfrLightBlue;
export var backgroundColor = bfrWhite;
export var surfaceColor = bfrLightgrey;
export var errorColor = bfrRed;
export var onPrimaryColor = bfrWhite;
export var onSecondaryColor = bfrBlue;
export var onSurfaceColor = bfrBlack;
export var onErrorColor = bfrBlack;
export var headerHeight = "42";
export var footerHeight = "42";
export var shadowPalette = {
    shadow1: "rgba(0, 0, 0, 0.2)",
    shadow2: "rgba(0, 0, 0, 0.14)",
    shadow3: "rgba(0, 0, 0, 0.12)",
};
var theme = {
    palette: {
        mode: "light",
        primary: {
            main: primaryColor,
            contrastText: onPrimaryColor,
        },
        secondary: {
            main: secondaryColor,
            contrastText: onSecondaryColor,
        },
        error: {
            main: errorColor,
            contrastText: onErrorColor,
        },
        text: {
            primary: onSurfaceColor,
        },
        background: {
            paper: surfaceColor,
            default: backgroundColor,
        },
        divider: primaryColor,
    },
    typography: {
        lineHeight: "1.6",
        h1: {
            fontSize: "2rem",
        },
        h2: {
            fontSize: "1.7em",
        },
        h3: {
            fontSize: "1.5em",
        },
        h5: {
            fontSize: "1.1em",
        },
    },
};
export var znTheme = responsiveFontSizes(createTheme(theme));
