import React from "react";
import { PageLayoutComponent } from "../../shared/components/layout/PageLayoutComponent";
import { MainWithSideLayout } from "../../shared/components/layout/MainWithSideLayoutComponent";
import { EvaluationDataProvider } from "../components/EvaluationDataContext";
import { EvaluationMainContent } from "../components/EvaluationMainContent";
import { EvaluationSideContent } from "../components/EvaluationSideContent";
import { useEvaluationPageComponent } from "./evaluationsPageUseCases";
export function EvaluationsMainComponent() {
    var model = useEvaluationPageComponent().model;
    return (React.createElement(PageLayoutComponent, null,
        React.createElement(EvaluationDataProvider, null,
            React.createElement(MainWithSideLayout, { side: React.createElement(EvaluationSideContent, null), sidebarTitle: model.sideBarTitle, main: React.createElement(EvaluationMainContent, { heading: model.mainHeading }) }))));
}
