import { List, Tooltip } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { environment } from "../../../../environment";
import { LastUpdateDateComponent } from "./LastUpdate-Date.component";
import { LastUpdateVersionComponent } from "./LastUpdate-Version.component";
export function LastUpdateComponent() {
    var version = environment.version;
    var theme = useTheme();
    var cmsVersion = window.sessionStorage.getItem("cms-version");
    return (React.createElement(Tooltip, { sx: {
            maxWidth: 200,
            marginBottom: "1.5em",
            marginLeft: "10px",
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            border: "2px solid ".concat(theme.palette.text.primary),
        }, arrow: true, title: React.createElement(React.Fragment, null,
            React.createElement(List, { dense: true },
                React.createElement(LastUpdateVersionComponent, { text: "server version@".concat(cmsVersion) }),
                React.createElement(LastUpdateVersionComponent, { text: "client version@".concat(version) }))) },
        React.createElement("span", null,
            React.createElement(LastUpdateDateComponent, null))));
}
