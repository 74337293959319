var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { FormControl, Tab, Tabs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import * as jsonld from "jsonld";
import { ErrorSnackbar } from "../../shared/components/ErrorSnackbar/ErrorSnackbar";
import { useTranslation } from "react-i18next";
function CustomTabPanel(props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    var t = useTranslation(["ExplanationPage"]).t;
    return (React.createElement("div", __assign({ role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-".concat(index), "aria-labelledby": "simple-tab-".concat(index) }, other, { style: { height: "100%" } }), value === index && (React.createElement(Box, { sx: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            height: "95%",
        } },
        other.notfound == "true" && (React.createElement("div", { style: { height: "100%" } },
            React.createElement(Typography, { variant: "h3" }, t("No_Records")))),
        other.notfound == "false" && (React.createElement(FormControl, { sx: { flex: "1 1 0" } },
            React.createElement("textarea", { style: { height: "-webkit-fill-available" }, id: "jsondata", placeholder: "Result will appear here!", name: "inputJSON", value: other.data, readOnly: true })))))));
}
function a11yProps(index) {
    return {
        id: "simple-tab-".concat(index),
        "aria-controls": "simple-tabpanel-".concat(index),
    };
}
export function JSONViewer(_a) {
    var _this = this;
    var data = _a.data, fetch = _a.fetch, view = _a.view, notfound = _a.notfound;
    var _b = __read(useState(""), 2), formattedData = _b[0], setFormattedData = _b[1];
    var _c = __read(useState(null), 2), error = _c[0], setError = _c[1];
    var beautifyJSON = function () {
        if (data && data.length > 0) {
            var formatData = JSON.stringify(data, null, 2);
            setFormattedData(formatData);
        }
    };
    var toRDF = function () { return __awaiter(_this, void 0, void 0, function () {
        var nquads, formatData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!(data && data.length > 0)) return [3, 2];
                    return [4, jsonld.toRDF(data, {
                            format: "application/n-quads",
                        })];
                case 1:
                    nquads = _a.sent();
                    formatData = JSON.stringify(nquads, null, 2);
                    setFormattedData(formatData);
                    return [2, true];
                case 2: return [2, false];
                case 3:
                    err_1 = _a.sent();
                    setError(err_1 instanceof Error
                        ? err_1.message
                        : "An unknown error occurred.");
                    return [2, false];
                case 4: return [2];
            }
        });
    }); };
    var _d = __read(React.useState(0), 2), value = _d[0], setValue = _d[1];
    useEffect(function () {
        switch (view) {
            case "LD":
                beautifyJSON();
                break;
            case "RDF":
                toRDF();
                break;
            case "JSON":
                beautifyJSON();
                break;
            default:
                beautifyJSON();
                break;
        }
    }, [data, view]);
    useEffect(function () {
        switch (view) {
            case "LD":
                setValue(0);
                break;
            case "RDF":
                setValue(1);
                break;
            case "JSON":
                setValue(2);
                break;
            default:
                break;
        }
    }, [view]);
    var handleChange = function (_event, newValue) {
        if (newValue == 0) {
            fetch("LD");
            beautifyJSON();
        }
        if (newValue == 1) {
            fetch("RDF");
            toRDF();
        }
        if (newValue == 2) {
            fetch("JSON");
        }
        setValue(newValue);
    };
    var handleCloseError = function () {
        setError(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: {
                width: "100%",
                height: "100%",
            } },
            React.createElement(Box, { sx: { borderBottom: 1, borderColor: "divider" } },
                React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": "basic tabs example", style: {
                        height: "95%",
                    } },
                    React.createElement(Tab, __assign({ label: "LD" }, a11yProps(0))),
                    React.createElement(Tab, __assign({ label: "RDF" }, a11yProps(1))),
                    React.createElement(Tab, __assign({ label: "JSON" }, a11yProps(2))))),
            React.createElement("div", { style: {
                    height: "95%",
                } },
                React.createElement(CustomTabPanel, { value: value, index: 0, data: formattedData, notfound: notfound }),
                React.createElement(CustomTabPanel, { value: value, index: 1, data: formattedData, notfound: notfound }),
                React.createElement(CustomTabPanel, { value: value, index: 2, data: formattedData, notfound: notfound }))),
        error && (React.createElement(ErrorSnackbar, { open: !!error, handleClose: handleCloseError }))));
}
