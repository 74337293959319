import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from "@mui/material";
import { Box, useTheme } from "@mui/system";
export function DialogComponent(props) {
    var theme = useTheme();
    var handleClose = function () { return props.cancelButton.onClick(); };
    var handleClickCancel = function () { return props.cancelButton.onClick(); };
    var submitButtonBox;
    if (props.submitButton !== undefined) {
        var onSubmitClick_1 = props.submitButton.onClick;
        var handleClickSubmit = function () { return onSubmitClick_1(); };
        submitButtonBox = (React.createElement(Box, { sx: {
                margin: theme.spacing(1),
                position: "relative",
            } },
            React.createElement(Button, { onClick: handleClickSubmit, sx: {
                    backgroundColor: theme.palette.primary.main,
                }, disabled: props.submitButton.disabled || props.loading, variant: "contained" }, props.submitButton.content),
            props.loading && (React.createElement(CircularProgress, { size: 24, sx: {
                    color: theme.palette.primary.main,
                    position: "absolute",
                    top: "10%",
                    left: "40%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                } }))));
    }
    return (React.createElement(Dialog, { open: true, onClose: handleClose, "aria-labelledby": "form-dialog-title", fullWidth: true, maxWidth: "lg" },
        React.createElement(DialogTitle, null, props.dialogTitle),
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, null, props.dialogContentText),
            props.dialogContent),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: handleClickCancel, sx: {
                    color: theme.palette.primary.main,
                }, disabled: props.loading }, props.cancelButton.content),
            submitButtonBox)));
}
