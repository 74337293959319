import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, } from "@mui/material";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./../utils/utils";
export function FilterMultiSelectionComponent(_a) {
    var selectedItems = _a.selectedItems, selectionOptions = _a.selectionOptions, label = _a.label, name = _a.name, actions = _a.actions;
    var t = useTranslation(["ExplanationPage"]).t;
    var classes = useStyles();
    var isAllSelected = selectionOptions.length > 0 &&
        selectedItems.length == selectionOptions.length;
    var handleChange = function (event) {
        var value = event.target.value;
        var index = value.indexOf("all");
        var selectedItemIndex = selectedItems.indexOf("all");
        if (index !== -1 && selectedItemIndex == -1) {
            if (selectedItems.length == 0) {
                event.target.value = selectionOptions.map(function (a) { return a.value; });
                selectedItems = selectionOptions.map(function (a) { return a.value; });
            }
            else {
                event.target.value = [];
                selectedItems = [];
            }
        }
        actions.handleChange(event);
    };
    return (React.createElement(FormControl, { className: classes.formControl, sx: { flex: "1 1 0" } },
        React.createElement(InputLabel, { id: "select-label" }, label),
        React.createElement(Select, { labelId: "select-label", id: "select", name: name, multiple: true, value: selectedItems, label: label, renderValue: function (selection) {
                var translated = selection.map(function (s) { return t(s); });
                return translated.join(", ");
            }, onChange: handleChange },
            React.createElement(MenuItem, { value: "all", classes: {
                    root: isAllSelected ? classes.selectedAll : "",
                } },
                React.createElement(ListItemIcon, null,
                    React.createElement(Checkbox, { classes: {
                            indeterminate: classes.indeterminateColor,
                        }, checked: isAllSelected, indeterminate: selectedItems.length > 0 &&
                            selectedItems.length < selectionOptions.length })),
                React.createElement(ListItemText, { classes: { primary: classes.selectAllText }, primary: t("Select_All") })),
            selectionOptions.map(function (item) { return (React.createElement(MenuItem, { key: item.value, value: item.value },
                React.createElement(Checkbox, { checked: selectedItems.indexOf(item.value) > -1 }),
                React.createElement(ListItemText, { primary: item.displayName }))); }))));
}
