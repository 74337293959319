import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { FormattedMicroorganismName } from "./FormattedMicroorganismName";
import { errorBarTooltipPlugin, drawErrorBars, logoPlugin, } from "./chartPlugins";
import { useTranslation } from "react-i18next";
var ChartCard = function (_a) {
    var chartKey = _a.chartKey, chartData = _a.chartData, chartRef = _a.chartRef, currentMicroorganism = _a.currentMicroorganism, yearOptions = _a.yearOptions, xAxisMax = _a.xAxisMax, downloadChart = _a.downloadChart;
    var t = useTranslation(["PrevalencePage"]).t;
    return (React.createElement(Box, { sx: {
            backgroundColor: "white",
            padding: 5,
            borderRadius: 2,
            boxShadow: 2,
            margin: "0 15px",
        } },
        React.createElement(Typography, { align: "center", gutterBottom: true, sx: {
                fontSize: "3rem",
                fontWeight: "bold",
                minHeight: "60px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                wordWrap: "break-word",
            } },
            React.createElement(FormattedMicroorganismName, { microName: currentMicroorganism })),
        React.createElement(Box, { sx: { marginBottom: 4 } },
            React.createElement(Bar, { data: {
                    labels: yearOptions,
                    datasets: [
                        {
                            label: chartKey,
                            data: yearOptions.map(function (year) {
                                return chartData[year] || {
                                    x: 0,
                                    y: year,
                                    ciMin: 0,
                                    ciMax: 0,
                                };
                            }),
                            backgroundColor: "#".concat(Math.floor(Math.random() * 16777215).toString(16)),
                        },
                    ],
                }, options: {
                    indexAxis: "y",
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: t("Prevalence %"),
                                color: "black",
                                font: {
                                    size: 16,
                                },
                            },
                            beginAtZero: true,
                            max: xAxisMax,
                            ticks: {
                                color: "black",
                                font: {
                                    size: 13,
                                },
                            },
                        },
                        y: {
                            title: {
                                display: true,
                                text: t("Year"),
                                color: "black",
                                font: {
                                    size: 16,
                                },
                            },
                            reverse: false,
                            ticks: {
                                color: "black",
                                font: {
                                    size: 13,
                                },
                                callback: function (_, index) {
                                    return yearOptions[index];
                                },
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "black",
                                padding: 5,
                                font: {
                                    size: 14,
                                },
                            },
                        },
                        tooltip: {
                            backgroundColor: "rgba(0, 0, 0, 1)",
                            titleFont: {
                                size: 14,
                            },
                            bodyFont: {
                                size: 12,
                            },
                            displayColors: true,
                            borderColor: "#fff",
                            borderWidth: 2,
                            caretPadding: 120,
                            yAlign: "center",
                            callbacks: {
                                label: function (context) {
                                    var year = parseInt(context.label || "", 10);
                                    var data = chartData[year] || {};
                                    var rawData = context.raw;
                                    return [
                                        "".concat(t("Prevalence"), ": ").concat(rawData.x, "%"),
                                        "".concat(t("CI_min"), ": ").concat(data.ciMin),
                                        "".concat(t("CI_max"), ": ").concat(data.ciMax),
                                        "".concat(t("Samples"), ": ").concat(data.numberOfSamples),
                                        "".concat(t("Positive"), ": ").concat(data.numberOfPositive),
                                    ];
                                },
                            },
                        },
                        customTexts: {
                            generatedOn: t("Generated on"),
                        },
                    },
                    animation: false,
                }, plugins: [
                    errorBarTooltipPlugin,
                    {
                        id: "customErrorBars",
                        afterDraw: function (chart) { return drawErrorBars(chart); },
                    },
                    logoPlugin,
                ], ref: chartRef }),
            React.createElement(Box, { sx: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 2,
                } },
                React.createElement(Button, { variant: "contained", size: "medium", onClick: function () { return downloadChart(chartRef, chartKey); }, sx: {
                        textTransform: "none",
                    } }, t("Download_Chart"))))));
};
export { ChartCard };
