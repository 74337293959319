var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import { EVALUATION_INFO } from "../../shared/infrastructure/router/routes";
import { category, diagramType, division, initialFilterSelection, matrix, microorganism, productionType, } from "../model/constants";
import { useEvaluationData } from "./EvaluationDataContext";
function toSelectionItem(stringItems, translate) {
    return stringItems.map(function (item) { return ({
        value: item,
        displayName: translate(item),
    }); });
}
var useEvaluationSideComponent = function () {
    var t = useTranslation(["ExplanationPage"]).t;
    var evaluationContext = useEvaluationData();
    var _a = __read(useState(""), 2), howtoContent = _a[0], setHowtoContent = _a[1];
    var _b = __read(useState(initialFilterSelection), 2), selectedFilters = _b[0], setSelectedFilters = _b[1];
    useEffect(function () {
        evaluationContext.updateFilters(selectedFilters);
    }, [selectedFilters]);
    var handleChange = function (key) {
        return function (value) {
            var newValue = Array.isArray(value) ? value : [value];
            setSelectedFilters(function (prev) {
                var _a;
                return __assign(__assign({}, prev), (_a = {}, _a[key] = newValue, _a));
            });
        };
    };
    var availableOptions = {
        matrix: toSelectionItem(matrix, t),
        productionType: toSelectionItem(productionType, t),
        diagramType: toSelectionItem(diagramType, t),
        category: toSelectionItem(category, t),
        microorganism: toSelectionItem(microorganism, t),
        division: toSelectionItem(division, t),
    };
    var selectionConfig = Object.keys(selectedFilters).map(function (key) {
        return {
            label: t(key.toUpperCase()),
            id: key,
            selectedItems: __spreadArray([], __read(selectedFilters[key]), false),
            selectionOptions: availableOptions[key],
            handleChange: function (event) {
                var value = event.target.value;
                handleChange(key)(value);
            },
        };
    });
    useEffect(function () {
        callApiService("".concat(EVALUATION_INFO, "?locale=").concat(i18next.language))
            .then(function (response) {
            if (response.data) {
                var data = response.data.data;
                setHowtoContent(data.attributes.content);
            }
            return response;
        })
            .catch(function (error) {
            console.error("Error fetching 'How To' content", error);
            throw error;
        });
    }, [i18next.language]);
    return {
        model: {
            selectionConfig: selectionConfig,
            howto: howtoContent,
            howToHeading: t("HOW_TO"),
        },
    };
};
export { useEvaluationSideComponent };
