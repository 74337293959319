import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTheme } from "@mui/system";
export function LastUpdateVersionComponent(props) {
    var theme = useTheme();
    var distanceStyle = {
        margin: 0,
        padding: 0,
    };
    var listTextStyle = {
        margin: 0,
        span: {
            fontSize: "0.75rem",
            fontStyle: "italic",
        },
    };
    var listIconStyle = {
        minWidth: "min-content",
        margin: "0.2em",
        padding: 0,
    };
    var dotIconStyle = {
        fontSize: "0.5rem",
        fill: theme.palette.primary.contrastText,
    };
    return (React.createElement(ListItem, { sx: distanceStyle },
        React.createElement(ListItemIcon, { sx: listIconStyle },
            React.createElement(FiberManualRecordIcon, { sx: dotIconStyle })),
        React.createElement(ListItemText, { primary: props.text, sx: listTextStyle })));
}
