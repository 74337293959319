import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import React from "react";
import { backgroundColor, primaryColor } from "../../style/Style-MainTheme";
export var SidebarComponent = function (_a) {
    var isOpen = _a.isOpen, handleOpenClick = _a.handleOpenClick, title = _a.title, children = _a.children;
    return (React.createElement(Collapse, { orientation: "horizontal", in: isOpen, collapsedSize: 50, sx: {
            maxWidth: "30%",
            borderRight: "1px solid gray",
            "&& .MuiCollapse-wrapperInner": {
                width: "100%",
            },
        } },
        isOpen && (React.createElement(React.Fragment, null,
            React.createElement("div", { style: {
                    zIndex: "101",
                    position: "relative",
                } },
                React.createElement(Box, { sx: {
                        display: "flex",
                        flexDirection: "row",
                        padding: 1,
                        justifyContent: "center",
                        gap: 2,
                    } },
                    React.createElement(Typography, { variant: "h3" }, title)),
                children),
            React.createElement("div", { style: {
                    float: "inline-end",
                } },
                React.createElement(IconButton, { color: "primary", "aria-label": "apply filter", onClick: handleOpenClick, sx: {
                        zIndex: "100",
                        position: "absolute",
                        top: "45%",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "22px",
                        cursor: "pointer",
                        color: "".concat(backgroundColor),
                        ":hover": {
                            backgroundColor: "".concat(primaryColor),
                        },
                        backgroundColor: "".concat(primaryColor),
                        background: "linear-gradient( 90deg , ".concat(backgroundColor, " 50%, ").concat(primaryColor, " 50%)"),
                        padding: "0",
                        height: "48px",
                        width: "48px",
                        marginLeft: "-24px",
                        "&& svg": {
                            marginLeft: "10px",
                            marginRight: "-10px",
                            fontSize: "xxx-large",
                        },
                    } },
                    React.createElement(KeyboardArrowLeftRoundedIcon, null))))),
        !isOpen && (React.createElement("div", { style: {
                backgroundColor: "".concat(primaryColor),
                height: "100%",
            } },
            React.createElement("div", { style: {
                    display: "inline-block",
                    transform: "rotate(-90deg) translateX(-110%)",
                    transformOrigin: "top left",
                    width: "max-content",
                    zIndex: "100",
                    position: "absolute",
                    left: "10px",
                } },
                React.createElement(Typography, { variant: "h3", sx: {
                        color: "".concat(backgroundColor),
                    } }, title)),
            React.createElement(IconButton, { color: "primary", "aria-label": "apply filter", onClick: handleOpenClick, sx: {
                    top: "45%",
                    left: "25px",
                    position: "absolute",
                    height: "48px",
                    width: "48px",
                    backgroundColor: "".concat(primaryColor),
                    color: "rgb(255, 255, 255)",
                    ":hover": {
                        backgroundColor: "".concat(primaryColor),
                    },
                    "&& svg": {
                        marginRight: "-15px",
                        fontSize: "xxx-large",
                    },
                } },
                React.createElement(KeyboardArrowRightRoundedIcon, null))))));
};
