var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useContext, useState, useEffect, } from "react";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import { MICROORGANISMS, SAMPLE_ORIGINS, MATRICES, SAMPLING_STAGES, MATRIX_GROUPS, SUPER_CATEGORY_SAMPLE_ORIGINS, PREVALENCES, } from "../../shared/infrastructure/router/routes";
import { MAX_PAGE_SIZE, } from "../../shared/model/CMS.model";
var DefaultPrevalenceDataContext = createContext(undefined);
export var usePrevalenceFilters = function () {
    var context = useContext(DefaultPrevalenceDataContext);
    if (!context) {
        throw new Error("usePrevalenceFilters must be used within a PrevalenceDataProvider");
    }
    return context;
};
function processApiResponse(apiData, setApiError) {
    var validEntries = [];
    var errors = [];
    apiData.forEach(function (item) {
        var _a, _b, _c, _d;
        try {
            var numberOfPositive = item.attributes.numberOfPositive != null
                ? item.attributes.numberOfPositive
                : 0;
            var ciMin = item.attributes.ciMin != null ? item.attributes.ciMin : 0;
            var ciMax = item.attributes.ciMax != null ? item.attributes.ciMax : 0;
            var entry = {
                id: item.id,
                matrix: item.attributes.matrix.data.attributes.name,
                matrixGroup: (_b = (_a = item.attributes.matrixGroup) === null || _a === void 0 ? void 0 : _a.data.attributes.name) !== null && _b !== void 0 ? _b : "",
                samplingStage: item.attributes.samplingStage.data.attributes.name,
                microorganism: item.attributes.microorganism.data.attributes.name,
                sampleOrigin: item.attributes.sampleOrigin.data.attributes.name,
                samplingYear: item.attributes.samplingYear,
                numberOfSamples: item.attributes.numberOfSamples,
                numberOfPositive: numberOfPositive,
                percentageOfPositive: item.attributes.percentageOfPositive,
                ciMin: ciMin,
                ciMax: ciMax,
                superCategorySampleOrigin: (_d = (_c = item.attributes.superCategorySampleOrigin) === null || _c === void 0 ? void 0 : _c.data.attributes.name) !== null && _d !== void 0 ? _d : "",
            };
            if (entry.numberOfSamples > 0 &&
                entry.numberOfPositive >= 0 &&
                entry.percentageOfPositive >= 0) {
                validEntries.push(entry);
            }
            else {
                errors.push("Entry with ID ".concat(item.id, " contains invalid data. Details: Samples: ").concat(entry.numberOfSamples, ", Positives: ").concat(entry.numberOfPositive, ", Percentage: ").concat(entry.percentageOfPositive));
            }
        }
        catch (err) {
            if (err instanceof Error) {
                errors.push("Entry with ID ".concat(item.id, " could not be processed due to an error: ").concat(err.message));
            }
            else {
                errors.push("Entry with ID ".concat(item.id, " could not be processed due to an unknown error."));
            }
        }
    });
    if (errors.length > 0) {
        setApiError("Error processing data: " + errors.join(", "));
    }
    return validEntries;
}
export var PrevalenceDataProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState([]), 2), selectedMicroorganisms = _b[0], setSelectedMicroorganisms = _b[1];
    var _c = __read(useState([]), 2), selectedSampleOrigins = _c[0], setSelectedSampleOrigins = _c[1];
    var _d = __read(useState([]), 2), selectedMatrices = _d[0], setSelectedMatrices = _d[1];
    var _e = __read(useState([]), 2), selectedSamplingStages = _e[0], setSelectedSamplingStages = _e[1];
    var _f = __read(useState([]), 2), selectedMatrixGroups = _f[0], setSelectedMatrixGroups = _f[1];
    var _g = __read(useState([]), 2), selectedYear = _g[0], setSelectedYear = _g[1];
    var _h = __read(useState([]), 2), selectedSuperCategory = _h[0], setSelectedSuperCategory = _h[1];
    var _j = __read(useState([]), 2), prevalenceData = _j[0], setPrevalenceData = _j[1];
    var _k = __read(useState({}), 2), searchParameters = _k[0], setSearchParameters = _k[1];
    var _l = __read(useState([]), 2), microorganismOptions = _l[0], setMicroorganismOptions = _l[1];
    var _m = __read(useState([]), 2), sampleOriginOptions = _m[0], setSampleOriginOptions = _m[1];
    var _o = __read(useState([]), 2), matrixOptions = _o[0], setMatrixOptions = _o[1];
    var _p = __read(useState([]), 2), samplingStageOptions = _p[0], setSamplingStageOptions = _p[1];
    var _q = __read(useState([]), 2), matrixGroupOptions = _q[0], setMatrixGroupOptions = _q[1];
    var _r = __read(useState([]), 2), superCategorySampleOriginOptions = _r[0], setSuperCategorySampleOriginOptions = _r[1];
    var _s = __read(useState(null), 2), error = _s[0], setError = _s[1];
    var _t = __read(useState(false), 2), loading = _t[0], setLoading = _t[1];
    var _u = __read(useState([]), 2), yearOptions = _u[0], setYearOptions = _u[1];
    var _v = __read(useState(false), 2), isSearchTriggered = _v[0], setIsSearchTriggered = _v[1];
    var _w = __read(useState(false), 2), showError = _w[0], setShowError = _w[1];
    var fetchPrevalenceData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, processedData, uniqueYears, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4, callApiService("".concat(PREVALENCES, "?populate=*&pagination[pageSize]=").concat(MAX_PAGE_SIZE))];
                case 2:
                    response = _a.sent();
                    if (response.data && response.data.data) {
                        processedData = processApiResponse(response.data.data, setError);
                        setPrevalenceData(processedData);
                        uniqueYears = Array.from(new Set(processedData.map(function (entry) { return entry.samplingYear; })));
                        setYearOptions(uniqueYears);
                    }
                    return [3, 5];
                case 3:
                    err_1 = _a.sent();
                    setError("Failed to fetch prevalence data: ".concat(err_1 instanceof Error ? err_1.message : "Unknown error"));
                    console.error(err_1);
                    return [3, 5];
                case 4:
                    setLoading(false);
                    return [7];
                case 5: return [2];
            }
        });
    }); };
    var fetchOptions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var fetchOption, _a, microorganisms, sampleOrigins, matrices, samplingStages, matrixGroups, superCategories, err_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, 5, 6]);
                    fetchOption = function (endpoint) { return __awaiter(void 0, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4, callApiService(endpoint)];
                                case 1:
                                    response = _a.sent();
                                    if (response.data && Array.isArray(response.data.data)) {
                                        return [2, response.data.data.map(function (item) { return ({
                                                name: item.attributes.name,
                                            }); })];
                                    }
                                    return [2, []];
                            }
                        });
                    }); };
                    return [4, Promise.all([
                            fetchOption(MICROORGANISMS),
                            fetchOption(SAMPLE_ORIGINS),
                            fetchOption(MATRICES),
                            fetchOption(SAMPLING_STAGES),
                            fetchOption(MATRIX_GROUPS),
                            fetchOption(SUPER_CATEGORY_SAMPLE_ORIGINS),
                        ])];
                case 2:
                    _a = __read.apply(void 0, [_b.sent(), 6]), microorganisms = _a[0], sampleOrigins = _a[1], matrices = _a[2], samplingStages = _a[3], matrixGroups = _a[4], superCategories = _a[5];
                    setMicroorganismOptions(microorganisms);
                    setSampleOriginOptions(sampleOrigins);
                    setMatrixOptions(matrices);
                    setSamplingStageOptions(samplingStages);
                    setMatrixGroupOptions(matrixGroups);
                    setSuperCategorySampleOriginOptions(superCategories);
                    return [4, fetchPrevalenceData()];
                case 3:
                    _b.sent();
                    return [3, 6];
                case 4:
                    err_2 = _b.sent();
                    setError("Failed to fetch options: ".concat(err_2 instanceof Error ? err_2.message : "Unknown error"));
                    console.error(err_2);
                    return [3, 6];
                case 5:
                    setLoading(false);
                    return [7];
                case 6: return [2];
            }
        });
    }); };
    useEffect(function () {
        fetchOptions();
    }, []);
    function filterData() {
        return prevalenceData.filter(function (entry) {
            return (selectedMicroorganisms.length === 0 ||
                selectedMicroorganisms.includes(entry.microorganism)) &&
                (selectedSampleOrigins.length === 0 ||
                    selectedSampleOrigins.includes(entry.sampleOrigin)) &&
                (selectedMatrices.length === 0 ||
                    selectedMatrices.includes(entry.matrix)) &&
                (selectedSamplingStages.length === 0 ||
                    selectedSamplingStages.includes(entry.samplingStage)) &&
                (selectedMatrixGroups.length === 0 ||
                    selectedMatrixGroups.includes(entry.matrixGroup)) &&
                (selectedSuperCategory.length === 0 ||
                    selectedSuperCategory.includes(entry.superCategorySampleOrigin)) &&
                (selectedYear.length === 0 ||
                    selectedYear.includes(entry.samplingYear));
        });
    }
    useEffect(function () {
        var updateOptionsBasedOnSelection = function () {
            var baseFilteredData = filterData();
            var generateOptionsForCategory = function (categoryKey) {
                return Array.from(new Set(baseFilteredData.map(function (entry) { return entry[categoryKey]; })))
                    .filter(function (name) { return name; })
                    .map(function (name) { return ({ name: name }); });
            };
            setMicroorganismOptions(selectedMicroorganisms.length > 0
                ? microorganismOptions
                : generateOptionsForCategory("microorganism"));
            setSampleOriginOptions(selectedSampleOrigins.length > 0
                ? sampleOriginOptions
                : generateOptionsForCategory("sampleOrigin"));
            setMatrixOptions(selectedMatrices.length > 0
                ? matrixOptions
                : generateOptionsForCategory("matrix"));
            setSamplingStageOptions(selectedSamplingStages.length > 0
                ? samplingStageOptions
                : generateOptionsForCategory("samplingStage"));
            setMatrixGroupOptions(selectedMatrixGroups.length > 0
                ? matrixGroupOptions
                : generateOptionsForCategory("matrixGroup"));
            setSuperCategorySampleOriginOptions(selectedSuperCategory.length > 0
                ? superCategorySampleOriginOptions
                : generateOptionsForCategory("superCategorySampleOrigin"));
            setYearOptions(selectedYear.length > 0
                ? yearOptions
                : Array.from(new Set(baseFilteredData.map(function (entry) { return entry.samplingYear; }))).sort(function (a, b) { return a - b; }));
        };
        updateOptionsBasedOnSelection();
    }, [
        selectedMicroorganisms,
        selectedSampleOrigins,
        selectedMatrices,
        selectedSamplingStages,
        selectedMatrixGroups,
        selectedSuperCategory,
        selectedYear,
        prevalenceData,
    ]);
    var fetchDataFromAPI = function () { return __awaiter(void 0, void 0, void 0, function () {
        var query, filters_1, addFilter, response, processedData, err_3, fetchError;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    query = "".concat(PREVALENCES, "?populate=*&pagination[pageSize]=").concat(MAX_PAGE_SIZE);
                    filters_1 = [];
                    addFilter = function (field, values) {
                        if (values.length > 0) {
                            filters_1.push(values
                                .map(function (value) {
                                return "filters[".concat(field, "][name][$eq]=").concat(encodeURIComponent(value));
                            })
                                .join("&"));
                        }
                    };
                    addFilter("microorganism", selectedMicroorganisms);
                    addFilter("sampleOrigin", selectedSampleOrigins);
                    addFilter("matrix", selectedMatrices);
                    addFilter("samplingStage", selectedSamplingStages);
                    addFilter("matrixGroup", selectedMatrixGroups);
                    if (selectedYear.length > 0) {
                        filters_1.push(selectedYear
                            .map(function (year) { return "filters[samplingYear][$eq]=".concat(year); })
                            .join("&"));
                    }
                    addFilter("superCategorySampleOrigin", selectedSuperCategory);
                    if (filters_1.length > 0) {
                        query += "&" + filters_1.join("&");
                    }
                    return [4, callApiService(query)];
                case 2:
                    response = _a.sent();
                    if (response.data && response.data.data) {
                        processedData = processApiResponse(response.data.data, setError);
                        setPrevalenceData(processedData);
                        setSearchParameters({
                            microorganism: selectedMicroorganisms,
                            sampleOrigin: selectedSampleOrigins,
                            matrix: selectedMatrices,
                            samplingStage: selectedSamplingStages,
                            matrixGroup: selectedMatrixGroups,
                            samplingYear: selectedYear.map(String),
                            superCategorySampleOrigin: selectedSuperCategory,
                        });
                        setIsSearchTriggered(true);
                    }
                    return [3, 5];
                case 3:
                    err_3 = _a.sent();
                    fetchError = err_3;
                    setError("Failed to fetch data: ".concat(fetchError.message));
                    console.error(err_3);
                    return [3, 5];
                case 4:
                    setLoading(false);
                    return [7];
                case 5: return [2];
            }
        });
    }); };
    var triggerSearch = function () {
        fetchDataFromAPI();
        setShowError(true);
    };
    var contextValue = {
        microorganismOptions: microorganismOptions,
        sampleOriginOptions: sampleOriginOptions,
        matrixOptions: matrixOptions,
        samplingStageOptions: samplingStageOptions,
        matrixGroupOptions: matrixGroupOptions,
        superCategorySampleOriginOptions: superCategorySampleOriginOptions,
        yearOptions: yearOptions,
        selectedMicroorganisms: selectedMicroorganisms,
        setSelectedMicroorganisms: setSelectedMicroorganisms,
        selectedSampleOrigins: selectedSampleOrigins,
        setSelectedSampleOrigins: setSelectedSampleOrigins,
        selectedMatrices: selectedMatrices,
        setSelectedMatrices: setSelectedMatrices,
        selectedSamplingStages: selectedSamplingStages,
        setSelectedSamplingStages: setSelectedSamplingStages,
        selectedMatrixGroups: selectedMatrixGroups,
        setSelectedMatrixGroups: setSelectedMatrixGroups,
        selectedYear: selectedYear,
        setSelectedYear: setSelectedYear,
        selectedSuperCategory: selectedSuperCategory,
        setSelectedSuperCategory: setSelectedSuperCategory,
        triggerSearch: triggerSearch,
        fetchDataFromAPI: fetchDataFromAPI,
        fetchOptions: fetchOptions,
        setIsSearchTriggered: setIsSearchTriggered,
        prevalenceData: isSearchTriggered ? prevalenceData : [],
        error: error,
        loading: loading,
        searchParameters: searchParameters,
        showError: showError,
        setShowError: setShowError,
        isSearchTriggered: isSearchTriggered,
    };
    return (React.createElement(DefaultPrevalenceDataContext.Provider, { value: contextValue }, children));
};
