import React from "react";
import { MainWithSideLayout } from "../../shared/components/layout/MainWithSideLayoutComponent";
import { PageLayoutComponent } from "../../shared/components/layout/PageLayoutComponent";
import { PrevalenceDataProvider } from "../components/PrevalenceDataContext";
import { PrevalenceMainContent } from "../components/PrevalenceMainContent";
import { PrevalenceSideContent } from "../components/PrevalenceSideContent";
import { usePrevalencePageComponent } from "./prevalenceUseCases";
export function PrevalenceMainComponent() {
    var model = usePrevalencePageComponent().model;
    return (React.createElement(PageLayoutComponent, null,
        React.createElement(PrevalenceDataProvider, null,
            React.createElement(MainWithSideLayout, { side: React.createElement(PrevalenceSideContent, null), sidebarTitle: model.sideBarTitle, main: React.createElement(PrevalenceMainContent, { heading: model.mainHeading }) }))));
}
