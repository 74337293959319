var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { primaryColor } from "../../style/Style-MainTheme";
var buttonAreaStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-left: 2em;\n    display: flex;\n    align-items: center;\n    box-sizing: inherit;\n"], ["\n    margin-left: 2em;\n    display: flex;\n    align-items: center;\n    box-sizing: inherit;\n"])));
var Button = styled("button")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 0.2em;\n    padding: 0;\n    width: 14px;\n    height: 9.19px;\n    cursor: pointer;\n    border: none;\n    background-color: ", ";\n    background-image: ", ";\n    background-repeat: no-repeat;\n    background-size: contain;\n    &::-moz-focus-inner {\n        border: none;\n    }\n    &:focus {\n        outline: none;\n    }\n    &:hover {\n        transform: scale(1.2, 1.2);\n    }\n"], ["\n    margin: 0.2em;\n    padding: 0;\n    width: 14px;\n    height: 9.19px;\n    cursor: pointer;\n    border: none;\n    background-color: ", ";\n    background-image: ", ";\n    background-repeat: no-repeat;\n    background-size: contain;\n    &::-moz-focus-inner {\n        border: none;\n    }\n    &:focus {\n        outline: none;\n    }\n    &:hover {\n        transform: scale(1.2, 1.2);\n    }\n"])), primaryColor, function (props) { return props.bgImage; });
var selectedFlagStyle = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 18px;\n    height: 11.79px;\n    &:hover {\n        transform: none;\n    }\n"], ["\n    width: 18px;\n    height: 11.79px;\n    &:hover {\n        transform: none;\n    }\n"])));
export function TranslationButtonsComponent() {
    var _a = __read(useState(true), 2), selectedFlag = _a[0], setSelectedFlag = _a[1];
    function changeAppLanguage(lang) {
        i18next.changeLanguage(lang);
    }
    useEffect(function () {
        switch (i18next.language) {
            case "de":
                setSelectedFlag(true);
                break;
            case "en":
                setSelectedFlag(false);
                break;
            default:
                setSelectedFlag(true);
                break;
        }
    });
    return (_jsxs("div", __assign({ css: buttonAreaStyle }, { children: [_jsx(Button, __assign({ type: "button", css: selectedFlag ? selectedFlagStyle : "none", bgImage: "url('/assets/germany_flag_icon.png')", onClick: function () {
                    localStorage.setItem("i18nextLng", "de");
                    changeAppLanguage("de");
                } }, { children: "\u00A0" })), _jsx(Button, __assign({ type: "button", css: selectedFlag ? "none" : selectedFlagStyle, bgImage: "url('/assets/united_kingdom_flag_icon.png')", onClick: function () {
                    localStorage.setItem("i18nextLng", "en");
                    changeAppLanguage("en");
                } }, { children: "\u00A0" }))] })));
}
var templateObject_1, templateObject_2, templateObject_3;
