var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import i18next from "i18next";
import * as lodash from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import { EXPLANATION } from "../../shared/infrastructure/router/routes";
function getTranslations(t) {
    var title = t("Title");
    return { title: title };
}
var useExplanationPageComponent = function () {
    var t = useTranslation(["InfoPage"]).t;
    var title = getTranslations(t).title;
    var _a = __read(useState({}), 2), explanationCollection = _a[0], setExplanationCollection = _a[1];
    var _b = __read(useState([]), 2), mainSection = _b[0], setMainSection = _b[1];
    var _c = __read(useState([]), 1), amrData = _c[0];
    var _d = __read(useState(""), 2), currentAMRID = _d[0], setCurrentAMRID = _d[1];
    var _e = __read(useState(false), 2), openAmrDialog = _e[0], setOpenAmrDialog = _e[1];
    var handleOpen = function (id) {
        setCurrentAMRID(id);
        setOpenAmrDialog(true);
    };
    var handleClose = function () {
        setOpenAmrDialog(false);
    };
    useEffect(function () {
        callApiService("".concat(EXPLANATION, "?locale=").concat(i18next.language))
            .then(function (response) {
            if (response.data) {
                var data = response.data.data;
                var cmsData = data.map(function (entry) { return ({
                    title: entry.attributes.title,
                    description: entry.attributes.description,
                    section: entry.attributes.section,
                    translatedSection: t(entry.attributes.section),
                }); });
                var orderedSections_1 = [
                    "HINTERGRUND",
                    "METHODEN",
                    "GRAPHIKEN",
                    "DATEN",
                ];
                var orderedCmsData = cmsData.sort(function (a, b) {
                    var aIndex = orderedSections_1.indexOf(a.section);
                    var bIndex = orderedSections_1.indexOf(b.section);
                    return aIndex - bIndex;
                });
                var sectionKeyedData = lodash.groupBy(orderedCmsData, "translatedSection");
                setExplanationCollection(sectionKeyedData);
                setMainSection(sectionKeyedData.MAIN || []);
            }
            return response;
        })
            .catch(function (error) {
            throw error;
        });
    }, [i18next.language, t]);
    return {
        model: {
            explanationCollection: explanationCollection,
            mainSection: mainSection,
            amrData: amrData,
            title: title,
            openAmrDialog: openAmrDialog,
            currentAMRID: currentAMRID,
        },
        operations: {
            handleOpen: handleOpen,
            handleClose: handleClose,
        },
    };
};
export { useExplanationPageComponent };
